export const API = {
  PORT: "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uMzWEDUe-wkO1nx3GypJ5bs3KVdNTe1lDI9_KSuhnDgdXMwoldM0Y5MNDLd4A_W1/n/bmyqpzeh1sew/b/media.yogamitra.live/o",
};

// export const API = {
//   PORT:
//     process.env.NODE_ENV == "development"
//       ? "http://192.168.235.245:9669"
//       : "https://classemitrans.stage02.obdemo.com",
// };

export const BASEURL = {
  PORT:
    process.env.NODE_ENV == "development"
      ? "http://192.168.235.245:9669"
      : "https://app.yogamitralive.com",
};

//https://app.yogamitralive.com/
//https://app.yogamitra.live

// export const BASEURL = {
//   PORT:
//     process.env.NODE_ENV == "development"
//       ? "http://192.168.235.245:9669"
//       : "https://classemitrans.stage02.obdemo.com",
// };

export const FRONTENDURL =
  process.env.NODE_ENV == "development"
    ? "http://192.168.235.245:3002"
    : "https://www.yogamitralive.com";

//https://www.yogamitralive.com
//https://yogamitra.live

// export const FRONTENDURL =
//   process.env.NODE_ENV == "development"
//     ? "http://192.168.235.245:3002"
//     : "https://classemitrafront.stage02.obdemo.com";
