import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
// import { Link } from "react-router-dom";
// import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { SearchInput, SearchSubmitButton } from "../Form/Form";
import moment from "moment";
import { CSVLink } from "react-csv";
import { FRONTENDURL } from "../../constant/api";

const headers = [
  // { label: "Id", key: "_id" },
  { label: "User Name", key: "userName" },
  { label: "Title", key: "title" },
  { label: "Price", key: "price" },
  { label: "Description", key: "description" },
  { label: "Class Count", key: "classCount" },
  { label: "Created At", key: "createdAt" },
];

const searchQueryHandler = (
  page,
  per_page,
  sortBy,
  order,
  userName = "",
  title = "",
  description = "",
  price,
  classCount,
  createdAt
) => {
  if (sortBy.length > 0) {
    if (sortBy === "user name") {
      sortBy = "userName";
    }
  } else {
    sortBy = "createdAt";
  }

  order = order.length > 0 ? order : "desc";

  let str = "";

  if (price) {
    str += `&price=${price}`;
  }
  if (classCount) {
    str += `&classCount=${classCount}`;
  }
  if (createdAt) {
    str += `&createdAt=${moment(new Date(createdAt)).format("YYYY-MM-DD")}`;
  }

  return `class-pass/all?page=${page}&per_page=${per_page}&sortBy=${sortBy}&order=${order}&username=${userName}&title=${title}&description=${description}${str}`;
};

const ViewAll = () => {
  const [classPasses, setClassPasses] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "created at",
    order: "desc",
  });

  // const MySwal = withReactContent(Swal);

  const { records_per_page, date_format, date_time_format } = useSelector(
    (state) => state.setting
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    clearErrors,
    // setError,
  } = useForm();

  const { request, response } = useRequest();
  // const { request: requestChangeStatus, response: responseChangeStatus } =
  //   useRequest();
  // const { request: requestDelete, response: responseDelete } = useRequest();

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }
    document.title = "Class Pass - YogaMitra";
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      setClassPasses(response.classPasses);
      setTotalDocuments((prev) =>
        response.totalDocuments ? response.totalDocuments : prev
      );
    }
  }, [response]);

  // useEffect(() => {
  //   if (responseChangeStatus) {
  //     const { id, status } = responseChangeStatus;

  //     const oldclassPasses = [...classPasses];
  //     const indexToChange = oldclassPasses.findIndex(
  //       (classPassesDetail) => classPassesDetail._id == id
  //     );
  //     oldclassPasses[indexToChange].isActive = status;

  //     setClassPasses(oldclassPasses);
  //   }
  // }, [responseChangeStatus]);

  // useEffect(() => {
  //   if (responseDelete) {
  //     const { id } = responseDelete;
  //     let newclassPasses = [...classPasses];
  //     newclassPasses = newclassPasses.filter(
  //       (classPassesDetail) => classPassesDetail._id != id
  //     );
  //     setClassPasses(newclassPasses);
  //     toast.success("Class-Pass has been deleted successfully.");
  //   }
  // }, [responseDelete]);

  const fetchMoreData = ({ selected }) => {
    setClassPasses([]);
    const { userName, title, description, price, classCount, createdAt } =
      getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        userName,
        title,
        description,
        price,
        classCount,
        createdAt
      )
    );
  };

  // const deleteHandler = (id) => {
  //   MySwal.fire({
  //     title: "Are you sure?",
  //     text: "You want to delete this Class-Pass?",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "Yes, delete it",
  //     cancelButtonText: "No, cancel",
  //     reverseButtons: true,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       requestDelete("DELETE", "class-pass", { id });
  //     } else if (result.isDismissed) {
  //     }
  //   });
  // };

  const onSearchHandler = (data) => {
    const { userName, title, description, price, classCount, createdAt } =
      getValues();
    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        userName,
        title,
        description,
        price,
        classCount,
        createdAt
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("userName");
    resetField("title");
    resetField("description");
    resetField("price");
    resetField("classCount");
    resetField("createdAt");
    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const { userName, title, description, price, classCount, createdAt } =
      getValues();

    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        userName,
        title,
        description,
        price,
        classCount,
        createdAt
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  const sortingHandler = (sortBy) => {
    const { userName, title, description, price, classCount, createdAt } =
      getValues();

    if (currentSort.sortBy === sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          newOrder,
          userName,
          title,
          description,
          price,
          classCount,
          createdAt
        )
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          "desc",
          userName,
          title,
          description,
          price,
          classCount,
          createdAt
        )
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  };

  const InputFields = [
    {
      label: "User Name",
      name: "userName",
      required: false,
    },
    {
      label: "Title",
      name: "title",
      required: false,
    },
    {
      label: "Price",
      name: "price",
      required: false,
      extras: {
        type: "number",
      },
    },
    {
      label: "Description",
      name: "description",
      required: false,
    },
    {
      label: "Class Count",
      name: "classCount",
      required: false,
      extras: {
        type: "number",
      },
    },
    {
      label: "Craeted At",
      name: "createdAt",
      isDate: true,
      clearErrors,
    },
  ];
  const redirectHandler = (id) => {
    window.open(
      `${FRONTENDURL}/class-pass/${id}`,
      "_blank",
      "noopener,noreferrer"
    );
  };
  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Class Passes"
        links={[{ to: "/", name: "Dashboard" }]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <CSVLink
                      data={classPasses.map((s) => {
                        const data = { ...s };
                        delete data._id;

                        data.createdAt = moment(s.createdAt).format(
                          date_format
                        );

                        data.price = `${data.currency == "INR" ? "₹" : "$"}${
                          data.price
                        }`;

                        return data;
                      })}
                      filename={"class-pass-reports.csv"}
                      // className="dropdown-item"
                      className="btn btn-primary mr-2"
                      target="_blank"
                      headers={headers}
                    >
                      Export
                    </CSVLink>
                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={sortingHandler}
                      mainData={classPasses}
                      tableHeading={[
                        "user name",
                        "title",
                        "price",
                        "description",
                        "class count",
                        "created at",
                      ]}
                      tableData={[
                        "userName",
                        "title",
                        "price",
                        "description",
                        "classCount",
                        "createdAt",
                      ]}
                      renderAs={{
                        price: (val, id, data) =>
                          val == 0
                            ? 0
                            : `${data.currency == "INR" ? "₹" : "$"}${val}`,
                      }}
                      links={[
                        {
                          isLink: false,
                          name: "View",
                          click: redirectHandler,
                          title: "View",
                          // show: (user) =>
                          //   user.isEmailVerified && user.isActive == "true",
                        },
                        //   // {
                        //   //   isLink: false,
                        //   //   name: "Deactivate",
                        //   //   click: changeStatusHandler,
                        //   //   title: "Click To Activate",
                        //   // },
                        //   // {
                        //   //   isLink: false,
                        //   //   name: "Activate",
                        //   //   click: changeStatusHandler,
                        //   //   title: "Click To Deactivate",
                        //   // },

                        //   // {
                        //   //   isLink: true,
                        //   //   to: "/class-pass/edit",
                        //   //   name: "Edit",
                        //   //   extraData: true,
                        //   // },
                        //   { isLink: false, name: "Delete", click: deleteHandler },
                      ]}
                      onlyDate={{
                        createdAt: "date",
                      }}
                      dontShowSort={[
                        "user name",
                        "title",
                        "price",
                        "description",
                        "class count",
                      ]}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={classPasses.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
