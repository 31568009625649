import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
// import { Link } from "react-router-dom";
// import { toast } from "react-toastify";
import moment from "moment";
import { CSVLink } from "react-csv";

import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { SearchInput, SearchSubmitButton } from "../Form/Form";
import { addOneToDate } from "../../util/fn";

const headers = [
  // { label: "Id", key: "_id" },
  { label: "User Name", key: "userName" },
  { label: "Plan Name", key: "planName" },
  { label: "Plan Price", key: "planPrice" },
  { label: "Expires On", key: "planExpiresOn" },
  { label: "Created At", key: "createdAt" },
];

const searchQueryHandler = (
  page,
  per_page,
  sortBy,
  order,
  userName = "",
  planName = "",
  planPrice,
  expiresOn,
  createdAt
) => {
  if (sortBy.length > 0) {
    if (sortBy === "created at") {
      sortBy = "createdAt";
    }
  } else {
    sortBy = "createdAt";
  }

  order = order.length > 0 ? order : "desc";

  let str = "";

  if (planPrice) {
    str += `&planPrice=${planPrice}`;
  }

  if (expiresOn) {
    str += `&expiresOn=${moment(new Date(expiresOn)).format("YYYY-MM-DD")}`;
  }

  if (createdAt) {
    str += `&createdAt=${moment(new Date(createdAt)).format("YYYY-MM-DD")}`;
  }

  return `subscription/all?page=${page}&per_page=${per_page}&sortBy=${sortBy}&order=${order}&username=${userName}&plan=${planName}${str}`;
};

const ViewAll = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "created at",
    order: "desc",
  });

  // const MySwal = withReactContent(Swal);

  const { records_per_page, date_format, date_time_format } = useSelector(
    (state) => state.setting
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    // setError,
    clearErrors,
  } = useForm();

  const { request, response } = useRequest();
  // const { request: requestChangeStatus, response: responseChangeStatus } =
  //   useRequest();
  // const { request: requestDelete, response: responseDelete } = useRequest();

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }
    document.title = "Subscriptions - YogaMitra";
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      setSubscriptions(response.subscriptions);
      setTotalDocuments((prev) =>
        response.totalDocuments ? response.totalDocuments : prev
      );
    }
  }, [response]);

  // useEffect(() => {
  //   if (responseChangeStatus) {
  //     const { id, status } = responseChangeStatus;

  //     const oldSubscriptions = [...subscriptions];
  //     const indexToChange = oldSubscriptions.findIndex(
  //       (subscriptionsDetail) => subscriptionsDetail._id == id
  //     );
  //     oldSubscriptions[indexToChange].isActive = status;

  //     setSubscriptions(oldSubscriptions);
  //   }
  // }, [responseChangeStatus]);

  // useEffect(() => {
  //   if (responseDelete) {
  //     const { id } = responseDelete;
  //     let newSubscriptions = [...subscriptions];
  //     newSubscriptions = newSubscriptions.filter(
  //       (subscriptionsDetail) => subscriptionsDetail._id != id
  //     );
  //     setSubscriptions(newSubscriptions);
  //     toast.success("Subscription has been deleted successfully.");
  //   }
  // }, [responseDelete]);

  const fetchMoreData = ({ selected }) => {
    setSubscriptions([]);
    const { userName, planName, planPrice, expiresOn, createdAt } = getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        userName,
        planName,
        planPrice,
        expiresOn,
        createdAt
      )
    );
  };

  // const deleteHandler = (id) => {
  //   MySwal.fire({
  //     title: "Are you sure?",
  //     text: "You want to delete this Subscription?",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "Yes, delete it",
  //     cancelButtonText: "No, cancel",
  //     reverseButtons: true,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       requestDelete("DELETE", "subscription", { id });
  //     } else if (result.isDismissed) {
  //     }
  //   });
  // };

  const onSearchHandler = (data) => {
    const { userName, planName, planPrice, expiresOn, createdAt } = getValues();
    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        userName,
        planName,
        planPrice,
        expiresOn,
        createdAt
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("userName");
    resetField("planName");
    resetField("planPrice");
    resetField("expiresOn");
    resetField("createdAt");

    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const { userName, planName, planPrice, expiresOn, createdAt } = getValues();

    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        userName,
        planName,
        planPrice,
        expiresOn,
        createdAt
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  const sortingHandler = (sortBy) => {
    if (sortBy === "planPrice") {
      return;
    }
    const { userName, planName, planPrice, expiresOn, createdAt } = getValues();

    if (currentSort.sortBy === sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          newOrder,
          userName,
          planName,
          planPrice,
          expiresOn,
          createdAt
        )
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          "desc",
          userName,
          planName,
          planPrice,
          expiresOn,
          createdAt
        )
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  };

  const InputFields = [
    {
      label: "User Name",
      name: "userName",
      required: false,
    },
    {
      label: "Plan Name",
      name: "planName",
      required: false,
    },
    {
      label: "Plan Price",
      name: "planPrice",
      required: false,
      extras: {
        type: "number",
      },
    },
    {
      label: "Expires On",
      name: "expiresOn",
      isDate: true,
      clearErrors,
      extras: {
        max: "2100-12-31",
      },
    },
    {
      label: "Created At",
      name: "createdAt",
      isDate: true,
      clearErrors,
    },
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Subscribers"
        links={[{ to: "/", name: "Dashboard" }]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <CSVLink
                      data={subscriptions.map((s) => {
                        const data = { ...s };
                        delete data._id;

                        data.createdAt = moment(data.createdAt).format(
                          date_format
                        );
                        data.planExpiresOn = moment(data.planExpiresOn).format(
                          date_time_format
                        );

                        data.planPrice =
                          data.planPrice == 0 ? 0 : `₹${data.planPrice}`;

                        return data;
                      })}
                      filename={"subscription-reports.csv"}
                      // className="dropdown-item"
                      className="btn btn-primary mr-2"
                      target="_blank"
                      headers={headers}
                    >
                      Export
                    </CSVLink>
                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={sortingHandler}
                      mainData={subscriptions}
                      tableHeading={[
                        "user name",
                        "plan name",
                        "plan price",
                        "expires on",
                        "created at",
                        // "multi details",
                      ]}
                      tableData={[
                        "userName",
                        "planName",
                        "planPrice",
                        "planExpiresOn",
                        "createdAt",
                        // "multiDetails",
                      ]}
                      // links={[
                      //   // {
                      //   //   isLink: false,
                      //   //   name: "Deactivate",
                      //   //   click: changeStatusHandler,
                      //   //   title: "Click To Activate",
                      //   // },
                      //   // {
                      //   //   isLink: false,
                      //   //   name: "Activate",
                      //   //   click: changeStatusHandler,
                      //   //   title: "Click To Deactivate",
                      //   // },
                      //   {
                      //     isLink: true,
                      //     to: "/subscription/edit",
                      //     name: "Edit",
                      //     extraData: true,
                      //   },
                      //   { isLink: false, name: "Delete", click: deleteHandler },
                      // ]}
                      onlyDate={{
                        createdAt: "date",
                        planExpiresOn: "dateTime",
                      }}
                      dontShowSort={[
                        "description",
                        "status",
                        "user name",
                        "plan name",
                        "plan price",
                        "expires on",
                        // "multi details",
                      ]}
                      renderAs={{
                        planPrice: (val) => (val == 0 ? 0 : `₹${val}`),
                      }}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={subscriptions.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
