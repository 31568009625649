import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";

import { SubTab } from "../Cms/TabNInput";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const ViewOne = (props) => {
  const { id } = props.match.params;

  const [requestedDemoData, setRequestedDemoData] = useState([]);

  const {
    response: responseRequestedDemoData,
    request: requestRequestedDemoData,
  } = useRequest();

  const { date_format } = useSelector((state) => state.setting);

  useEffect(() => {
    requestRequestedDemoData("GET", `request-demo/${id}`);

    document.title = "View Requested Demo - YogaMitra";
  }, []);

  useEffect(() => {
    if (responseRequestedDemoData) {
      const {
        name,
        companyName,
        email,
        state,
        contact,
        country,
        zip,
        employeeCountRange,
        message,
        createdAt,
      } = responseRequestedDemoData.requestDemo;
      setRequestedDemoData([
        { title: "Name", value: name },
        // { title: "Company Name", value: companyName },
        { title: "Email", value: email },
        { title: "Phone Number", value: contact },
        { title: "State", value: state },
        { title: "Country", value: country },
        { title: "Zip", value: zip },
        // { title: "Employee Range", value: employeeCountRange },
        { title: "Message", value: message },
        {
          title: "Registered On",
          value: <Moment format={date_format}>{createdAt}</Moment>,
        },
      ]);
    }
  }, [responseRequestedDemoData]);

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="View Requested Demo"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/requested-demos", name: "Back To Requested Demos" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card card-custom gutter-b">
            <div className="card-header card-header-tabs-line">
              <div className="card-toolbar">
                <ul
                  className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                  role="tablist"
                >
                  {["Requested Demo Information"].map((data, index) => (
                    <SubTab key={index} name={data} index={index} />
                  ))}
                </ul>
              </div>
            </div>

            <div className="card-body px-0">
              <div className="tab-content px-10">
                <div
                  className={`tab-pane active`}
                  id={`kt_apps_contacts_view_tab_3`}
                  role="tabpanel"
                >
                  {requestedDemoData.length > 0 &&
                    requestedDemoData.map((user, index) => (
                      <div key={index} className="form-group row my-2">
                        <label className="col-4 col-form-label">
                          {user.title}
                        </label>
                        <div className="col-8">
                          <span className="form-control-plaintext font-weight-bolder">
                            {user.value}
                          </span>
                        </div>
                      </div>
                    ))}

                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOne;
