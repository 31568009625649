import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import OneLevelMenu from "../Menu/OneLevel";
import ZeroLevelMenu from "../Menu/ZeroLevel";
import { menu } from "../../util/data";
import { Arrow } from "../../util/Svg";
import { sidebarToggle } from "../../store/auth/action";

const Sidebar = () => {
  const [fullSidebar, setFullSidebar] = useState(true);
  const { isMobileSidebarOpen } = useSelector((state) => state.auth);

  const { pathname } = useLocation();
  const dispatch = useDispatch();

  //classList.toggle
  const onClickSidebarHandler = () => {
    if (fullSidebar) {
      document.body.classList.add("aside-minimize");
      document.querySelector("#kt_aside_toggle").classList.add("active");
    } else {
      document.body.classList.remove("aside-minimize");
      document.querySelector("#kt_aside_toggle").classList.remove("active");
    }

    setFullSidebar((prev) => !prev);
    document.body.classList.remove("aside-minimize-hover");
  };

  const onHoverSidebarHandler = () => {
    if (!fullSidebar) {
      document.body.classList.remove("aside-minimize");
      document.body.classList.add("aside-minimize-hover");
    }
  };

  const onMouseLeaveHandler = () => {
    if (!fullSidebar) {
      document.body.classList.add("aside-minimize");
      document.body.classList.remove("aside-minimize-hover");
    }
  };

  return (
    <>
      <div
        className={`aside aside-left  aside-fixed  d-flex flex-column flex-row-auto ${
          isMobileSidebarOpen ? "aside-on" : ""
        }`}
        id="kt_aside"
        onMouseOver={onHoverSidebarHandler}
        onMouseLeave={onMouseLeaveHandler}
      >
        <div className="brand flex-column-auto " id="kt_brand">
          <Link to="/" className="brand-logo">
            <img alt="Logo" src="./logo-light.png" />
          </Link>

          <button
            onClick={onClickSidebarHandler}
            // className={`brand-toggle btn btn-sm px-0 ${
            //   fullSidebar ? "" : "active"
            // }`}
            className={`brand-toggle btn btn-sm px-0`}
            id="kt_aside_toggle"
          >
            <span className="svg-icon svg-icon svg-icon-xl">
              <Arrow />
            </span>{" "}
          </button>
        </div>

        <div
          className="aside-menu-wrapper flex-column-fluid"
          id="kt_aside_menu_wrapper"
        >
          <div
            id="kt_aside_menu"
            className="aside-menu my-4 "
            data-menu-vertical="1"
            data-menu-scroll="1"
            data-menu-dropdown-timeout="500"
          >
            <ul className="menu-nav ">
              {menu.map((menu, index) =>
                menu.subMenu.length > 0 ? (
                  <OneLevelMenu key={index} menu={menu} pathname={pathname} />
                ) : (
                  <ZeroLevelMenu key={index} menu={menu} pathname={pathname} />
                )
              )}

              {/* <li className="menu-section">
              <h4 className="menu-text">Custom</h4>
              <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
            </li>
            <li
              className="menu-item menu-item-submenu"
              data-menu-toggle="hover"
            >
              <a className="menu-link menu-toggle">
                <span className="svg-icon menu-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    //
                    //xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <rect
                        fill="#000000"
                        x="4"
                        y="4"
                        width="7"
                        height="7"
                        rx="1.5"
                      />
                      <path
                        d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                    </g>
                  </svg>
                </span>
                <span className="menu-text">Menu level 1</span>
                <i className="menu-arrow"></i>
              </a>
              <div className="menu-submenu">
                <i className="menu-arrow"></i>
                <ul className="menu-subnav">
                  <li className="menu-item menu-item-parent">
                    <span className="menu-link">
                      <span className="menu-text">Menu level 1</span>
                    </span>
                  </li>
                  <li
                    className="menu-item menu-item-submenu"
                    data-menu-toggle="hover"
                  >
                    <a  className="menu-link menu-toggle">
                      <i className="menu-bullet menu-bullet-line">
                        <span></span>
                      </i>
                      <span className="menu-text">Menu level 2</span>
                      <i className="menu-arrow"></i>
                    </a>
                    <div className="menu-submenu">
                      <i className="menu-arrow"></i>
                      <ul className="menu-subnav">
                        <li className="menu-item">
                          <a  className="menu-link">
                            <i className="menu-bullet menu-bullet-dot">
                              <span></span>
                            </i>
                            <span className="menu-text">Menu level 3</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li> */}
            </ul>
          </div>
        </div>
      </div>
      {isMobileSidebarOpen && (
        <div
          onClick={() =>
            dispatch(sidebarToggle({ isMobileSidebarOpen: false }))
          }
          className="aside-overlay"
        ></div>
      )}
    </>
  );
};

export default Sidebar;
