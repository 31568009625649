import React, { useState, useEffect } from "react";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { FRONTENDURL } from "../../constant/api";

const OBJ_TABLE = {
  Date: "createdAt",
  Amount: "amount",
  "Item-name": "itemName",
};

const ViewOne = (props) => {
  const { id: payoutId } = props.match.params;

  const [payouts, setPayouts] = useState([]);

  const { response: responsePayout, request: requestPayout } = useRequest();

  useEffect(() => {
    requestPayout("GET", `admin/payouts/${payoutId}`);

    document.title = "View Payout - YogaMitra";
  }, []);

  useEffect(() => {
    if (responsePayout) {
      setPayouts(responsePayout.payouts);
    }
  }, [responsePayout]);

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="View Payout"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/payouts", name: "Back To Payouts" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-body">
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={{}}
                      // sortingHandler={sortingHandler}
                      mainData={payouts}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      links={[]}
                      onlyDate={{
                        createdAt: "date",
                        startDate: "dateTime",
                        endDate: "dateTime",
                      }}
                      renderAs={{
                        itemName: (x, y, data) => (
                          <>
                            <a
                              href={`${FRONTENDURL}/${data.itemType}/${data.itemId}`}
                              target="_blank"
                            >
                              {data.itemName}
                            </a>
                          </>
                        ),
                        amount: (am) => `₹${am}`,
                      }}
                      dontShowSort={["createdAt", "amount", "itemName"]}
                    />

                    {/* {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={payouts.length}
                      />
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOne;
