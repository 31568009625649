import Login from "../pages/Login/Login";
import ForgotPassword from "../components/ForgotPassword/ForgotPassword";
import ResetPassword from "../components/ResetPassword/ResetPassword";

import Dashboard from "../pages/Dashboard/Dashboard";
import ChangePassword from "../components/ChangePassword/ChangePassword";
import Profile from "../components/Profile/Profile";

import ViewAllSettings from "../components/Settings/ViewAll";
import ViewPrefix from "../components/Settings/ViewPrefix";
import AddSetting from "../components/Settings/Add";
import EditSetting from "../components/Settings/Edit";

import ViewAllCms from "../components/Cms/ViewAll";
import ViewOneCms from "../components/Cms/NewViewOne";
import AddCms from "../components/Cms/NewAdd";
import EditCms from "../components/Cms/NewEdit";

import ViewAllEmailTemplate from "../components/EmailTemplate/ViewAll";
import AddEmailTemplate from "../components/EmailTemplate/Add";
import EditEmailTemplate from "../components/EmailTemplate/Edit";

import ViewAllEmailLogs from "../components/EmailLog/ViewAll";

import ViewAllSeekers from "../components/Seeker/ViewAll";
import AddSeeker from "../components/Seeker/Add";
import EditSeeker from "../components/Seeker/Edit";
import ViewSeeker from "../components/Seeker/ViewOne";
import ChangePasswordSeeker from "../components/Seeker/ChangePassword";
import ViewAllSeekersReport from "../components/Seeker/ViewAllReport";

import ViewAllCreators from "../components/Creator/ViewAll";
import AddCreator from "../components/Creator/Add";
import EditCreator from "../components/Creator/Edit";
import ViewCreator from "../components/Creator/ViewOne";
import ChangePasswordCreator from "../components/Creator/ChangePassword";
import ViewAllCreatorsReport from "../components/Creator/ViewAllReport";

import ViewAllFaqs from "../components/Faq/ViewAll";
import AddFaq from "../components/Faq/Add";
import EditFaq from "../components/Faq/Edit";

import ViewAllRequestedDemos from "../components/RequestDemo/ViewAll";
import ViewRequestedDemo from "../components/RequestDemo/ViewOne";

import ViewAllBankAcount from "../components/BankAccount/viewAll";
import ViewAllBankAcountList from "../components/BankAccount/viewAllAccount";
import AddBankAcount from "../components/BankAccount/Add";

import ViewAllTutorialCategories from "../components/Tutorial/Category/ViewAll";
import AddTutorialCategories from "../components/Tutorial/Category/Add";
import EditTutorialCategories from "../components/Tutorial/Category/Edit";

import ViewAllProfileCategories from "../components/ProfileCategories/ViewAll";
import AddProfileCategory from "../components/ProfileCategories/Add";
import EditProfileCategory from "../components/ProfileCategories/Edit";

import ViewAllProfileSubCategories from "../components/ProfileCategories/SubProfileCategories/ViewAll";
import AddProfileSubCategory from "../components/ProfileCategories/SubProfileCategories/Add";
import EditProfileSubCategory from "../components/ProfileCategories/SubProfileCategories/Edit";

import AddTutorial from "../components/Tutorial/Add";
import ViewAllTutorials from "../components/Tutorial/ViewAll";
import EditTutorial from "../components/Tutorial/Edit";

import TutorialSetting from "../components/Tutorial/Setting";

import AddPlan from "../components/Plan/Add";
import ViewAllPlan from "../components/Plan/ViewAll";
import EditPlan from "../components/Plan/Edit";

import AddImage from "../components/Image/Add";
import ViewAllImage from "../components/Image/ViewAll";
import EditImage from "../components/Image/Edit";

import ViewAllSubscription from "../components/Subscription/ViewAll";

import ViewAllClass from "../components/Class/ViewAll";

import ViewAllEvent from "../components/Event/ViewAll";

import ViewAllPass from "../components/Pass/ViewAll";

import AddEmployeeCount from "../components/EmployeeCount/Add";
import ViewAllEmployeeCount from "../components/EmployeeCount/ViewAll";
import EditEmployeeCount from "../components/EmployeeCount/Edit";

import ViewAllOrder from "../components/Order/ViewAll";
import ViewOneOrder from "../components/Order/ViewOne";

import ViewAllTransaction from "../components/Transaction/ViewAll";

import ViewAllReview from "../components/Review/ViewAll";

import ViewAllPayouts from "../components/Payout/ViewAll";
import ViewOne from "../components/Payout/ViewOne";

import ViewAllReportReasons from "../components/ReportReason/ViewAll";
import AddReportReason from "../components/ReportReason/Add";
import EditReportReason from "../components/ReportReason/Edit";

import ViewAllReportedUsers from "../components/ReportedUser/ViewAll";

import ViewAllCourses from "../components/courses/viewAll";

import ViewWalletHistory from "../components/Wallet/View";
import UpdateWallet from "../components/Wallet/Add";

import ViewAllSmsTemplate from "../components/SmsTemplate/ViewAll";
import AddSmsTemplate from "../components/SmsTemplate/Add";
import EditSmsTemplate from "../components/SmsTemplate/Edit";

import ViewAllSmsLogs from "../components/SmsLog/ViewAll";
import ViewAllWhatsAppLogs from "../components/WhatsAppLog/ViewAll"

export const privateRoutes = [
  { path: "/", component: Dashboard },
  { path: "/setting", component: ViewAllSettings },
  { path: "/setting/prefix/:prefix", component: ViewPrefix },
  { path: "/setting/add", component: AddSetting },
  { path: "/setting/edit/:id", component: EditSetting },
  { path: "/cms", component: ViewAllCms },
  { path: "/cms/view/:id", component: ViewOneCms },
  { path: "/cms/add", component: AddCms },
  { path: "/cms/edit/:id", component: EditCms },
  { path: "/email-template", component: ViewAllEmailTemplate },
  { path: "/email-template/add", component: AddEmailTemplate },
  { path: "/email-template/edit/:id", component: EditEmailTemplate },
  { path: "/email-logs", component: ViewAllEmailLogs },
  { path: "/whatsapp-logs", component: ViewAllWhatsAppLogs },
  { path: "/change-password", component: ChangePassword },
  { path: "/my-profile", component: Profile },
  { path: "/seekers", component: ViewAllSeekers },
  { path: "/seeker/add", component: AddSeeker },
  { path: "/seeker/edit/:id", component: EditSeeker },
  { path: "/seeker/view/:id", component: ViewSeeker },
  { path: "/seeker/change-password/:id", component: ChangePasswordSeeker },
  { path: "/creators", component: ViewAllCreators },
  // { path: "/creator/add", component: AddCreator },
  { path: "/creator/edit/:id", component: EditCreator },
  { path: "/creator/view/:id", component: ViewCreator },
  { path: "/creator/change-password/:id", component: ChangePasswordCreator },
  { path: "/bank-accounts", component: ViewAllBankAcount },
  { path: "/bank-accounts-listing", component: ViewAllBankAcountList },
  { path: "/bank-account/add", component: AddBankAcount },
  { path: "/faq", component: ViewAllFaqs },
  { path: "/faq/add", component: AddFaq },
  { path: "/faq/edit/:id", component: EditFaq },
  { path: "/requested-demos", component: ViewAllRequestedDemos },
  { path: "/requested-demos/view/:id", component: ViewRequestedDemo },
  { path: "/tutorial-categories", component: ViewAllTutorialCategories },
  { path: "/tutorial-category/add", component: AddTutorialCategories },
  { path: "/tutorial-category/edit/:id", component: EditTutorialCategories },
  { path: "/tutorial/add", component: AddTutorial },
  { path: "/tutorial/edit/:id", component: EditTutorial },
  { path: "/tutorial", component: ViewAllTutorials },
  { path: "/tutorial/setting", component: TutorialSetting },
  { path: "/plan/add", component: AddPlan },
  { path: "/plan", component: ViewAllPlan },
  { path: "/plan/edit/:id", component: EditPlan },
  { path: "/subscribers", component: ViewAllSubscription },
  { path: "/class", component: ViewAllClass },
  { path: "/event", component: ViewAllEvent },
  { path: "/class-pass", component: ViewAllPass },
  { path: "/system-images/add", component: AddImage },
  { path: "/system-images", component: ViewAllImage },
  { path: "/system-images/edit/:id", component: EditImage },
  { path: "/employee-count/add", component: AddEmployeeCount },
  { path: "/employee-count", component: ViewAllEmployeeCount },
  { path: "/employee-count/edit/:id", component: EditEmployeeCount },

  { path: "/profile-categories", component: ViewAllProfileCategories },
  { path: "/profile-category/add", component: AddProfileCategory },
  { path: "/profile-category/edit/:id", component: EditProfileCategory },

  {
    path: "/profile-categories/sub/:id",
    component: ViewAllProfileSubCategories,
  },
  { path: "/profile-categories/sub/:id/add", component: AddProfileSubCategory },
  {
    path: "/profile-categories/sub/:subId/edit/:id",
    component: EditProfileSubCategory,
  },
  {
    path: "/orders",
    component: ViewAllOrder,
  },
  {
    path: "/order/view/:id",
    component: ViewOneOrder,
  },
  {
    path: "/transactions",
    component: ViewAllTransaction,
  },
  {
    path: "/reviews",
    component: ViewAllReview,
  },
  { path: "/payouts", component: ViewAllPayouts },
  { path: "/payouts/view/:id", component: ViewOne },

  { path: "/report-reasons", component: ViewAllReportReasons },
  { path: "/report-reason/add", component: AddReportReason },
  { path: "/report-reason/edit/:id", component: EditReportReason },

  { path: "/reported-users", component: ViewAllReportedUsers },
  { path: "/courses", component: ViewAllCourses },
  { path: "/wallet/view/:id", component: ViewWalletHistory },
  { path: "/wallet/add/:id", component: UpdateWallet },
  { path: "/sms-template", component: ViewAllSmsTemplate },
  { path: "/sms-template/add", component: AddSmsTemplate },
  { path: "/sms-template/edit/:id", component: EditSmsTemplate },
  { path: "/sms-logs", component: ViewAllSmsLogs },
  { path: "/seeker-reports", component: ViewAllSeekersReport },
  { path: "/creator-reports", component: ViewAllCreatorsReport },
];

export const notPrivateRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/reset-password/:token", component: ResetPassword },
];
