import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Input,
  RenderInputFields,
  SubmitButton,
  SelectInput,
  Textarea,
  CKEditorInput,
} from "../Form/Form";

const Add = () => {
  const [categories, setCategories] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    clearErrors,
    setError,
  } = useForm();
  const ckEditorRef = useRef();
  const { response, request } = useRequest();
  const { response: responseFetchCategories, request: requestFetchCategories } =
    useRequest();

  const history = useHistory();

  useEffect(() => {
    document.title = "Add Tutorial - YogaMitra";
    requestFetchCategories(
      "GET",
      "tutorial-category/all?page=1&per_page=99999&sortBy=name&order=asc&name=&isActive=true"
    );
  }, []);

  useEffect(() => {
    if (responseFetchCategories) {
      setCategories(responseFetchCategories.categories);
    }
  }, [responseFetchCategories]);

  useEffect(() => {
    if (response) {
      toast.success("Tutorial has been created successfully.");
      history.push("/tutorial");
    }
  }, [response]);

  const onSubmit = (data) => {
    const { title, video, image, category, description } = data;
    const formData = new FormData();

    if (!description) {
      setError("description", {
        type: "manual",
      });
      return;
    }
    formData.append("title", title);
    formData.append("video", video);
    formData.append("categoryId", category);
    formData.append("description", description);
    formData.append("file", image[0]);

    request("POST", "tutorial", formData);
  };

  const InputFields = [
    [
      {
        Component: SelectInput,
        label: "Category",
        name: "category",
        registerFields: {
          required: true,
          minLength: 1,
        },
        children: (
          <>
            <option value="">{"Select Category"}</option>
            {categories.length > 0 &&
              categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
          </>
        ),
      },
      {
        Component: Input,
        label: "Title",
        type: "text",
        name: "title",
        registerFields: {
          required: true,
        },
      },
    ],
    [
      {
        Component: Input,
        label: "Video Link",
        type: "text",
        name: "video",
        registerFields: {
          required: false,
        },
      },
      {
        Component: Input,
        label: "Thumbnail",
        name: "image",
        type: "file",
        registerFields: {
          required: true,
        },
        inputData: {
          accept: "image/*",
        },
      },
    ],
    [
      // {
      //   Component: Textarea,
      //   label: "Description",
      //   name: "description",
      //   colClass: "col-lg-12",
      //   registerFields: {
      //     //   required: true,
      //   },
      // },
      {
        Component: CKEditorInput,
        colClass: "col-xl-12",
        label: "Description",
        name: "description",
        registerFields: {
          required: true,
        },
        getValues,
        setValue,
        trigger,
        inputData: {
          onInstanceReady: (editor) => {
            ckEditorRef.current = editor;
          },
        },
        clearErrors,
        isEdit: false,
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add Tutorial"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/tutorial", name: "Back To Tutorials" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Add New Tutorial
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />
                    {errors.description?.type === "manual" && (
                      <p className="error-msg" style={{ color: "#F64E60" }}>
                        *Descripton is required
                      </p>
                    )}
                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
