import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Moment from "moment";
import Papa from "papaparse";
import Modal, { defaultStyles } from "react-modal";
import { CSVLink } from "react-csv";
import moment from "moment";

import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { SearchInput, SearchSubmitButton } from "../Form/Form";
import { addOneToDate } from "../../util/fn";
import { BASEURL } from "../../constant/api";

const OBJ_TABLE = {
  "start Date": "startDate",
  "end date": "endDate",
  file: "filePath",
};

const headers = [
  // { label: "Id", key: "_id" },
  { label: "Start Date", key: "userName" },
  { label: "End Date", key: "_id" },
  { label: "Download", key: "createdAt" },
];

const searchQueryHandler = (
  page,
  per_page,
  sortBy,
  order,
  dateFrom = "1970-01-01",
  dateTo
) => {
  sortBy = OBJ_TABLE[sortBy];

  order = order.length > 0 ? order : "desc";

  dateFrom = dateFrom.length > 0 ? dateFrom : "1970-01-01";

  dateTo = dateTo ? addOneToDate(new Date(dateTo)) : addOneToDate(new Date());

  return `admin/bank-account-files?page=${page}&per_page=${per_page}&sortBy=${sortBy}`;
};

const ViewAll = () => {
  const [bankAccounts, setBankAccounts] = useState([]);
  const [allBankAccounts, setAllBankAccounts] = useState([]);

  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [perPage, setPerPage] = useState(0);
  const [modalData, setModalData] = useState([]);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "Created On",
    order: "desc",
  });

  const MySwal = withReactContent(Swal);

  const { records_per_page, date_format } = useSelector(
    (state) => state.setting
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    setError,
    clearErrors,
  } = useForm();

  const { request, response } = useRequest();
  const { request: bankAccountRequest, response: bankAccountResponse } =
    useRequest();

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }
    document.title = "Bank Account - YogaMitra";
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      const accounts = response.accounts;

      const newBankAccount = accounts.slice(0, perPage);

      setBankAccounts(newBankAccount);
      setAllBankAccounts(accounts);
      setTotalDocuments(accounts.length);
    }
  }, [response]);

  useEffect(() => {
    if (bankAccountResponse) {
      toast.success("Uploaded successfully.");
    }
  }, [bankAccountResponse]);

  const fetchMoreData = ({ selected }) => {
    setPage(selected + 1);

    const b = +perPage * selected;
    const a = +perPage * (selected + 1) - 1;

    let newBankAccount = allBankAccounts.filter((data, index) => {
      if (index >= b && index <= a) {
        return data;
      }
      return false;
    });

    setBankAccounts(newBankAccount);
  };

  const perPageChangeHandler = (event) => {
    setPage(1);

    setPerPage(event.target.value);
  };

  const sortingHandler = (sortBy) => {
    const { name, method, dateFrom, dateTo } = getValues();

    if (currentSort.sortBy === sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(page, perPage, sortBy, newOrder, name, dateTo)
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          "desc",
          name,
          method,
          dateFrom,
          dateTo
        )
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  };

  const InputFields = [
    {
      label: "Date From",
      name: "dateFrom",
      isDate: true,
      clearErrors,
    },
    {
      label: "Date To",
      name: "dateTo",
      isDate: true,
      clearErrors,
      otherRegisterFields: {
        manual: true,
        feedback: "'To Date' cannot be smaller than 'From Date'",
      },
    },
  ];

  const handleOnChangeImport = (e) => {
    const file = e.target.files[0];
    const fileReader = new FileReader();
    if (file) {
      fileReader.onload = async ({ target }) => {
        const csv = Papa.parse(target.result, {
          header: true,
          transformHeader: function (h) {
            return typeof h == "string" ? h.toLowerCase() : h;
          },
        });
        const parsedData = csv?.data;

        let newParseData = parsedData
          .filter(
            (data) =>
              data.activated === "1" &&
              data.email.length > 0 &&
              data.id.length > 0
          )
          .map((data) => ({ id: data.id, email: data.email }));

        if (newParseData.length > 0) {
          setModalData(newParseData);
          setShowModal(true);
        } else {
          toast.error("Data is invalid. Please select another file");
        }

        // console.log(notReceivedHeader);
        e.target.files = null;
      };
      fileReader.readAsText(file);
    }
  };

  const importHandler = () => {
    setShowModal(false);
    bankAccountRequest("POST", "admin/bank-account", {
      details: modalData,
    });
  };

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Bank Accounts"
        links={[{ to: "/", name: "Dashboard" }]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <Link to="/bank-accounts-listing">
                      <button className="choosefile_modal btn btn-primary mr-2 mb-0">
                        Bank Account Listing
                      </button>
                    </Link>
                    <label
                      for="csvFileInput"
                      className="choosefile_modal btn btn-primary mr-2 mb-0"
                    >
                      Import File
                      <input
                        className="form-control "
                        type={"file"}
                        id={"csvFileInput"}
                        accept=".csv"
                        // defaultValue="cewfe"
                        onChange={handleOnChangeImport}
                        style={{ display: "none" }}
                      />
                    </label>
                    {false && (
                      <CSVLink
                        data={bankAccounts.map((s) => {
                          const data = { ...s };
                          data.createdAt = moment(s.createdAt).format(
                            date_format
                          );
                          data.amount = `${data.currency == "INR" ? "₹" : "$"}${
                            data.amount
                          }`;
                          return data;
                        })}
                        filename={"order-reports.csv"}
                        // className="dropdown-item"
                        className="btn btn-primary mr-2"
                        target="_blank"
                        headers={headers}
                      >
                        Export
                      </CSVLink>
                    )}

                    {false && (
                      <a
                        /*href="#!"*/
                        className="btn btn-primary dropdown-toggle mr-2"
                        data-toggle="collapse"
                        data-target="#collapseOne6"
                      >
                        Search
                      </a>
                    )}

                    {/* <Link to="/order/add" className="btn btn-primary mr-2">
                      Add New Oder
                    </Link> */}
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      {/* <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div> */}
                    </div>
                  </div>
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={sortingHandler}
                      mainData={bankAccounts}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      // links={[
                      //   {
                      //     isLink: true,
                      //     to: "/order/view",
                      //     name: "View",
                      //     extraData: true,
                      //   },
                      //   // { isLink: false, name: "Delete", click: deleteHandler },
                      // ]}
                      onlyDate={{
                        createdAt: "date",
                        startDate: "date",
                        endDate: "date",
                      }}
                      renderAs={{
                        filePath: (val) => {
                          return (
                            <a
                              href={BASEURL.PORT + "/" + val}
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                            >
                              <button className="btn btn-primary">
                                <i className="fas fa-download" />
                                Download File
                              </button>
                            </a>
                          );
                        },
                      }}
                      dontShowSort={["end date", "start Date", "file"]}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={bankAccounts.length}
                      />
                    )}
                    <Modal
                      isOpen={showModal}
                      ariaHideApp={false}
                      className="model_block"
                      onRequestClose={() => setShowModal(false)}
                    >
                      <div
                        className="modal-dialog modal-dialog-centered modal-lg"
                        role="document"
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Bank Account</h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={() => setShowModal(false)}
                            >
                              <i aria-hidden="true" className="ki ki-close"></i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="table-responsive">
                              <table
                                className="table dataTable table-head-custom table-head-bg table-borderless table-vertical-center"
                                id="taskTable"
                              >
                                <thead>
                                  <tr className="text-uppercase">
                                    <th>ID</th>
                                    <th>Email</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {modalData.map((data, index) => (
                                    <tr key={index}>
                                      <td>{data.id}</td>
                                      <td>{data.email}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <button
                                className="btn btn-primary"
                                onClick={importHandler}
                              >
                                Import File
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
