import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { SubTab } from "../Cms/TabNInput";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { FRONTENDURL } from "../../constant/api";

const Activated = () => (
  <span className="label label-lg label-light-success label-inline">
    Activated
  </span>
);

const Deactivated = () => (
  <span className="label label-lg label-light-danger label-inline">
    Deactivated
  </span>
);

const ViewOne = (props) => {
  const { id: orderId } = props.match.params;

  const [orderData, setOrderData] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [currency, setCurrency] = useState("$");

  const { response: responseOrderData, request: requestOrderData } =
    useRequest();

  const { response: responseRefund, request: requestRefund } = useRequest();

  const { date_format } = useSelector((state) => state.setting);

  useEffect(() => {
    requestOrderData("GET", `order/${orderId}`);

    document.title = "View Order - YogaMitra";
  }, []);

  useEffect(() => {
    if (responseOrderData) {
      const { method, totalAmount, orderItems, createdAt, currency, extras } =
        responseOrderData.order;

      let orderD = [];
      let currencySign = currency == "INR" ? "₹" : "$";

      if (extras?.viaWallet) {
        orderD.push({
          title: "Paid via Wallet",
          value: currencySign + extras.viaWallet,
        });
      }

      setOrderData([
        {
          title: "Items Price",
          value:
            currencySign + orderItems.reduce((acc, cv) => acc + cv.price, 0),
        },
        {
          title: "Processing Fees",
          value: currencySign + responseOrderData.processingFees,
        },
        ...orderD,

        { title: "Total Amount Paid Online", value: currencySign + totalAmount },
        { title: "Method", value: method },
        {
          title: "Ordered On",
          value: <Moment format={date_format}>{createdAt}</Moment>,
        },
      ]);

      setOrderItems(orderItems);
      setCurrency(currencySign);
    }
  }, [responseOrderData]);

  useEffect(() => {
    if (responseRefund) {
      toast.success("Refund processed successfully.");
    }
  }, [responseRefund]);

  const refundHandler = (itemId) => {
    requestRefund("POST", "admin/refund", {
      id: itemId,
      orderId,
      purchaserId: responseOrderData.order.userId,
    });
  };

  const redirectHandler = (order) => {
    const type =
      order.type == "Class"
        ? "class"
        : order.type == "Event"
        ? "event"
        : "class-pass";

    window.open(
      `${FRONTENDURL}/${type}/${order.id}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const userRedirectHandler = (order) => {
    window.open(
      `${FRONTENDURL}/user/${order.creatorId}`,
      "_blank",
      "noopener,noreferrer"
    );
  };
  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="View Order"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/orders", name: "Back To Orders" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card card-custom gutter-b">
            <div className="card-header card-header-tabs-line">
              <div className="card-toolbar">
                <ul
                  className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                  role="tablist"
                >
                  {["Order Information"].map((data, index) => (
                    <SubTab key={index} name={data} index={index} />
                  ))}
                </ul>
              </div>
            </div>

            <div className="card-body px-0">
              <div className="tab-content px-10">
                <div
                  className={`tab-pane active`}
                  id={`kt_apps_contacts_view_tab_3`}
                  role="tabpanel"
                >
                  {orderData.length > 0 &&
                    orderData.map((order, index) => (
                      <div key={index} className="form-group row my-2">
                        <label className="col-4 col-form-label">
                          {order?.title}
                        </label>
                        <div className="col-8">
                          <span className="form-control-plaintext font-weight-bolder">
                            {order?.value}
                          </span>
                        </div>
                      </div>
                    ))}

                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header"></div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="walletTransaction">
                    <div className="row"></div>

                    <div className="row">
                      <div className="col-12">
                        <div className="Theme_table">
                          <div className="table-responsive scrollbar">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Item Id</th>
                                  <th>Title</th>
                                  <th>Creator Name</th>
                                  <th>Price</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {orderItems.length > 0 &&
                                  orderItems.map((order) => (
                                    <tr key={order.id}>
                                      <td
                                        onClick={() => {
                                          redirectHandler(order);
                                        }}
                                        style={{
                                          color: "#1d74f5",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {order.id}
                                      </td>
                                      <td>{order.title} </td>
                                      <td
                                        onClick={() =>
                                          userRedirectHandler(order)
                                        }
                                        style={{
                                          color: "#1d74f5",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {order.creatorName}{" "}
                                      </td>
                                      <td>
                                        <span>
                                          {currency}
                                          {order.price}
                                        </span>
                                      </td>
                                      <td>
                                        <button
                                          onClick={() =>
                                            refundHandler(order.id)
                                          }
                                          className="btn btn-primary"
                                        >
                                          Refund
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOne;
