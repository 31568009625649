import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { Input, RenderInputFields, SubmitButton } from "../Form/Form";

const Edit = (props) => {
  const { id: seekerId } = props.match.params;
  const history = useHistory();

  const [notToEdit, setNotToEdit] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
  } = useForm();

  const { response: responseFetchUser, request: requestFetchSeeker } =
    useRequest();

  const { response, request } = useRequest();

  useEffect(() => {
    if (seekerId) {
      requestFetchSeeker("GET", `user/${seekerId}`);
      document.title = "Edit Seeker - YogaMitra";
    }
  }, [seekerId]);

  useEffect(() => {
    if (responseFetchUser) {
      const { name, email, contact, username } = responseFetchUser.user;
      setValue("name", name);
      setValue("email", email);
      setValue("contact", contact);
      setValue("username", username);
      setNotToEdit(responseFetchUser.user.providers.length > 0);
    }
  }, [responseFetchUser]);

  useEffect(() => {
    if (response) {
      toast.success("Seeker has been updated successfully.");
      history.push("/seekers");
    }
  }, [response]);

  const onSubmit = (data) => {
    const { name, email, contact, username } = data;

    const {
      email: oldEmail,
      contact: oldContact,
      username: oldUsername,
    } = responseFetchUser.user;

    let updates = {};

    if (email.trim() != oldEmail) {
      updates.email = email;
    }

    if (contact) {
      const str = contact.toString();
      if (str.length > 0) {
        updates.contact = contact;
      }
    }

    if (username && oldUsername && username.trim() != oldUsername) {
      if (!/^[a-zA-Z0-9\\_.]+$/.test(username.trim())) {
        setError("username", {
          type: "manual",
        });
        return;
      }
    }

    if (username) {
      updates.username = username.trim();
    }

    request("PUT", "user", {
      name,
      ...updates,
      userId: seekerId,
    });
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "Name",
        type: "text",
        name: "name",
        registerFields: {
          required: true,
          pattern: /^[A-Za-z ]+$/,
        },
        registerFieldsFeedback: {
          pattern: "Name can only contain letters.",
        },
      },
      {
        Component: Input,
        label: "Email",
        type: "email",
        name: "email",
        registerFields: {
          required: true,
          pattern:
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        },
        registerFieldsFeedback: {
          pattern: "The email field must be a valid email address.",
        },
        inputData: {
          disabled: notToEdit,
        },
      },
    ],
    [
      {
        Component: Input,
        label: "Username",
        type: "text",
        name: "username",
        registerFields: {
          required: true,
          pattern: /^[a-zA-Z0-9\\_.]+$/,
        },
        registerFieldsFeedback: {
          pattern:
            "Username can contain only letters, numbers and symbols . or _",
        },
        otherRegisterFields: {
          manual: true,
          feedback:
            "Username can contain only letters, numbers and symbols . or _",
        },
      },
      {
        Component: Input,
        label: "Phone Number",
        type: "number",
        name: "contact",
        registerFields: {
          // required: true,
          // minLength: 10,
          // maxLength: 10,
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Seeker"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/seekers" /*backPageNum: page */ },
            name: "Back To Seekers",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Edit Seeker
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
