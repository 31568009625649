import {
  Store,
  Driver,
  Dashboard,
  Cms,
  Settings,
  SystemManagement,
  Users,
  Seeker,
  Creator,
  HelpCentre,
  Tutorials,
  Category,
  Review,
  Order,
  Transactions,
  Reports,
  Payout,
  Courses,
  Creators,
} from "./Svg";

export const menu = [
  {
    name: "Dashboard",
    path: "/",
    Svg: Dashboard,
    subMenu: [],
    highlight: ["/my-profile", "/change-password"],
    subHighlight: [],
  },
  {
    name: "Seekers",
    path: "/seekers",
    Svg: Seeker,
    subMenu: [],
    highlight: ["/seeker/add"],
    subHighlight: ["/seeker/view", "/seeker/edit", "/seeker/change-password"],
  },
  {
    name: "Creators",
    path: "/creators",
    Svg: Creators,
    subMenu: [],
    highlight: ["/creator/add"],
    subHighlight: [
      "/creator/view",
      "/creator/edit",
      "/creator/change-password",
    ],
  },
  {
    name: "Courses",
    path: "/courses",
    Svg: Courses,
    subMenu: [],
    highlight: [],
    subHighlight: [],
  },
  {
    name: "Categories",
    path: "/profile-categories",
    Svg: Category,
    subMenu: [],

    highlight: ["/profile-categories", "/profile-category/add"],
    subHighlight: ["/profile-categories/sub", "/profile-category/edit"],
  },
  {
    name: "Bank accounts",
    path: "/bank-accounts",
    Svg: Seeker,
    subMenu: [],
    highlight: [
      "/bank-accounts/view",
      "/bank-accounts-listing",
      "/bank-account/add",
    ],
    subHighlight: ["/bank-accounts/view"],
  },
  {
    name: "Orders",
    path: "/orders",
    Svg: Order,
    subMenu: [],

    highlight: ["/orders"],
    subHighlight: [],
  },
  {
    name: "Transactions",
    path: "/transactions",
    Svg: Transactions,
    subMenu: [],

    highlight: ["/transactions"],
    subHighlight: [],
  },
  {
    name: "Reviews",
    path: "/reviews",
    Svg: Review,
    subMenu: [],

    highlight: ["/reviews"],
    subHighlight: [],
  },
  {
    name: "Payouts",
    path: "/payouts",
    Svg: Payout,
    subMenu: [],

    highlight: ["/payouts"],
    subHighlight: [],
  },
  {
    name: "Reports",
    Svg: Reports,
    subMenu: [
      {
        name: "Subscribers",
        path: "/subscribers",
        highlight: [],
        subHighlight: [],
      },
      {
        name: "Class",
        path: "/class",
        highlight: [],
        subHighlight: [],
      },
      {
        name: "Workshop",
        path: "/event",
        highlight: [],
        subHighlight: [],
      },
      {
        name: "Pass",
        path: "/class-pass",
        highlight: [],
        subHighlight: [],
      },
      {
        name: "Seeker",
        path: "/seeker-reports",
        highlight: [],
        subHighlight: [],
      },
      {
        name: "Creator",
        path: "/creator-reports",
        highlight: [],
        subHighlight: [],
      },
    ],
    highlight: [],
    subHighlight: [],
  },
  {
    name: "System Management",
    Svg: SystemManagement,
    subMenu: [
      {
        name: "Cms Pages",
        path: "/cms",
        highlight: ["/cms/add"],
        subHighlight: ["/cms/view", "/cms/edit"],
      },
      {
        name: "Email Templates",
        path: "/email-template",
        highlight: ["/email-template/add"],
        subHighlight: ["/email-template/edit"],
      },
      {
        name: "Email Logs",
        path: "/email-logs",
        highlight: [],
        subHighlight: [],
      },
      {
        name: "Sms Templates",
        path: "/sms-template",
        highlight: ["/sms-template/add"],
        subHighlight: ["/sms-template/edit"],
      },
      {
        name: "Sms Logs",
        path: "/sms-logs",
        highlight: [],
        subHighlight: [],
      },
      {
        name: "Whatsapp Logs",
        path: "/whatsapp-logs",
        highlight: [],
        subHighlight: [],
      },
      {
        name: "Plans",
        path: "/plan",
        highlight: ["/plan/add", "/setting/prefix/Payment"],
        subHighlight: [],
      },
      {
        name: "System Images",
        path: "/system-images",
        highlight: ["/system-images", "/system-images/add"],
        subHighlight: ["/system-images/edit"],
      },
    ],
    highlight: ["/setting/prefix/Payment", "/system-images/add"],
    subHighlight: ["/system-images/edit"],
  },
  {
    name: "Help Centre",
    Svg: HelpCentre,
    subMenu: [
      {
        name: "Settings",
        path: "/setting/prefix/Help",
        highlight: [],
        subHighlight: [],
      },
      {
        name: "FAQs",
        path: "/faq",
        highlight: ["/faq/add"],
        subHighlight: ["/faq/edit", "/faq/view"],
      },
      {
        name: "Requested Demo",
        path: "/requested-demos",
        highlight: ["/requested-demos"],
        subHighlight: ["/requested-demos/view"],
      },
      {
        name: "Report Reasons",
        path: "/report-reasons",
        highlight: ["/report-reason/add"],
        subHighlight: ["/report-reason/edit"],
      },
      {
        name: "Reported users",
        path: "/reported-users",
        highlight: [],
        subHighlight: [],
      },
    ],
    highlight: [],
    subHighlight: [],
  },
  {
    name: "Tutorials",
    Svg: Tutorials,
    subMenu: [
      {
        name: "Tutorials",
        path: "/tutorial",
        highlight: ["/tutorial/add"],
        subHighlight: ["/tutorial/edit"],
      },
      {
        name: "Tutorial Categories",
        path: "/tutorial-categories",
        highlight: ["/tutorial-categories", "/tutorial-category/add"],
        subHighlight: ["/tutorial-category/edit"],
      },
      {
        name: "Tutorial Page Setting",
        path: "/tutorial/setting",
        highlight: [],
        subHighlight: [],
      },
    ],
    highlight: ["/tutorial-categories", "/tutorial-category/add"],
    subHighlight: ["/tutorial-category/edit"],
  },
  {
    name: "Settings",
    Svg: Settings,
    subMenu: [
      {
        name: "Site Setting",
        path: "/setting/prefix/Site",
        highlight: [],
        subHighlight: [],
      },
      {
        name: "Reading Setting",
        path: "/setting/prefix/Reading",
        highlight: [],
        subHighlight: [],
      },
      {
        name: "Social Setting",
        path: "/setting/prefix/Social",
        highlight: [],
        subHighlight: [],
      },
      // {
      //   name: "Refund Setting",
      //   path: "/setting/prefix/Refund",
      //   highlight: [],
      //   subHighlight: [],
      // },
      // {
      //   name: "Employee Count",
      //   path: "/employee-count",
      //   highlight: [],
      //   subHighlight: [],
      // },
    ],
    highlight: ["/setting", "/setting/add", "/employee-count/add"],
    subHighlight: ["/setting/edit", "/employee-count/edit"],
  },
];
