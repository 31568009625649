import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { API } from "../../constant/api";

import { Input, RenderInputFields, SubmitButton, Textarea } from "../Form/Form";

const Setting = () => {
  const [oldBanner, setOldBanner] = useState("");
  const [oldThumbnail, setOldThumbnail] = useState("");
  const [isThumbnailDeleted, setIsThumbnailDeleted] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const {
    response: responseFetchTutorial,
    request: requestFetchTutorialSetting,
  } = useRequest();

  const { response, request } = useRequest();

  useEffect(() => {
    requestFetchTutorialSetting("GET", `master/tutorial-setting`);
    document.title = "Tutorial Page Setting - YogaMitra";
  }, []);

  useEffect(() => {
    if (responseFetchTutorial) {
      const {
        pageTitle,
        pageDescription,
        pageBanner,
        title,
        description,
        video,
        thumbnail,
      } = responseFetchTutorial.setting;

      setValue("pageTitle", pageTitle);
      setValue("pageDescription", pageDescription);
      setValue("title", title);
      setValue("description", description);
      setValue("video", video);
      setOldBanner(pageBanner != "undefined" ? pageBanner : "");
      setOldThumbnail(thumbnail != "undefined" ? thumbnail : "");
    }
  }, [responseFetchTutorial]);

  useEffect(() => {
    if (response) {
      setIsThumbnailDeleted(false);
      // setValue("thumbnail", "");
      reset();
      toast.success("Setting has been updated successfully.");

      // if (response.pageBanner) {
      //   setOldBanner(response.pageBanner);
      // }

      // if (response.thumbnail) {
      //   setOldThumbnail(response.thumbnail);
      // }

      requestFetchTutorialSetting("GET", `master/tutorial-setting`);
    }
  }, [response]);

  const onSubmit = (data) => {
    const {
      pageTitle,
      pageDescription,
      banner,
      title,
      description,
      video,
      thumbnail,
    } = data;
    const formData = new FormData();

    if (banner.length !== 0) {
      formData.append("banner", banner[0]);
    }

    if (thumbnail?.length !== 0) {
      formData.append("thumbnail", thumbnail[0]);
    }

    formData.append("pageTitle", pageTitle);
    formData.append("pageDescription", pageDescription);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("video", video);
    formData.append("oldPageBanner", oldBanner);

    if (oldThumbnail) {
      formData.append("oldThumbnail", oldThumbnail);
    } else {
      formData.append("isThumbnailDeleted", isThumbnailDeleted);

      formData.append("oldThumbnail", responseFetchTutorial.setting.thumbnail);
    }
    request("POST", "master/tutorial-setting", formData);
  };

  const handleImageRemove = () => {
    setIsThumbnailDeleted(true);
    setOldThumbnail("");
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "Page Title",
        type: "text",
        name: "pageTitle",
        registerFields: {
          //   required: true,
        },
      },
      {
        Component: Input,
        label: "Page Banner",
        name: "banner",
        type: "file",
        registerFields: {
          //   required: false,
        },
        inputData: {
          accept: "image/*",
        },
        children: oldBanner && (
          <img
            src={`${API.PORT}/${oldBanner}`}
            width={100}
            height={100}
            alt=""
            style={{ cursor: "pointer" }}
            data-fancybox
          />
        ),
      },
    ],
    [
      {
        Component: Textarea,
        label: "Page Description",
        name: "pageDescription",
        colClass: "col-lg-12 mt-3",
        registerFields: {
          //   required: true,
        },
      },
    ],
    [
      {
        Component: Input,
        label: "Title",
        type: "text",
        name: "title",
        registerFields: {
          //   required: true,
        },
      },
      {
        Component: Input,
        label: "Video Link",
        type: "text",
        name: "video",
        registerFields: {
          //   required: true,
        },
      },
      {
        Component: Input,
        label: "Thumbnail",
        name: "thumbnail",
        type: "file",
        registerFields: {
          //   required: false,
        },
        inputData: {
          accept: "image/*",
        },
        children: oldThumbnail && (
          <div style={{ position: "relative", width: "fit-content" }}>
            <span onClick={handleImageRemove} className="remove-icon">
              <i class="fa fa-times"></i>
            </span>
            <img
              src={`${API.PORT}/${oldThumbnail}`}
              width={100}
              height={100}
              alt=""
              style={{ cursor: "pointer" }}
              data-fancybox
            />
          </div>
        ),
      },
    ],
    [
      {
        Component: Textarea,
        label: "Description",
        name: "description",
        colClass: "col-lg-12 mt-3",
        registerFields: {
          //   required: true,
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Tutorial Page Setting"
        links={[
          { to: "/", name: "Dashboard" },
          //   {
          //     to: { pathname: "/tutorial" },
          //     name: "Back To Tutorials",
          //   },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Tutorial Page Setting
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
