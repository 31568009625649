import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { API } from "../../constant/api";

import {
  Input,
  RenderInputFields,
  SubmitButton,
  SelectInput,
  Textarea,
  CKEditorInput,
} from "../Form/Form";

const Edit = (props) => {
  const { id: tutorialId } = props.match.params;
  const history = useHistory();
  const ckEditorRef = useRef();

  const [categories, setCategories] = useState([]);
  const [oldImage, setOldImage] = useState("");
  const [isThumbnailDeleted, setIsThumbnailDeleted] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    reset,
    setError,
    clearErrors,
  } = useForm();

  const { response: responseFetchTutorial, request: requestFetchTutorial } =
    useRequest();

  const { response, request } = useRequest();

  useEffect(() => {
    if (tutorialId) {
      requestFetchTutorial("GET", `tutorial/${tutorialId}`);
      document.title = "Edit Tutorial - YogaMitra";
    }
  }, [tutorialId]);

  useEffect(() => {
    if (responseFetchTutorial) {
      const { title, video, categoryId, image, description } =
        responseFetchTutorial.tutorial[0];
      setOldImage(image);
      setValue("title", title);
      setValue("video", video);
      setValue("description", description);
      setCategories(responseFetchTutorial.categories);
      setTimeout(() => setValue("category", categoryId), 0);
    }
  }, [responseFetchTutorial]);

  useEffect(() => {
    if (response) {
      toast.success("Tutorial has been updated successfully.");
      history.push("/tutorial");
    }
  }, [response]);

  const onSubmit = (data) => {
    const { title, video, category, image, description } = data;

    const formData = new FormData();

    if (image.length !== 0) {
      formData.append("file", image[0]);
    }

    formData.append("title", title);
    formData.append("video", video);
    formData.append("categoryId", category);
    formData.append("id", tutorialId);
    formData.append("description", description);
    if (oldImage) {
      formData.append("oldImage", oldImage);
    } else {
      formData.append("isThumbnailDeleted", isThumbnailDeleted);
      formData.append("oldImage", responseFetchTutorial.tutorial[0].image);
    }

    request("PUT", "tutorial", formData);
  };
  const handleImageRemove = () => {
    setIsThumbnailDeleted(true);
    setOldImage("");
  };

  const InputFields = [
    [
      {
        Component: SelectInput,
        label: "Category",
        name: "category",
        registerFields: {
          required: true,
          minLength: 1,
        },
        children: (
          <>
            <option value="">{"Select Category"}</option>
            {categories.length > 0 &&
              categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
          </>
        ),
      },
      {
        Component: Input,
        label: "Title",
        type: "text",
        name: "title",
        registerFields: {
          required: true,
        },
      },
    ],
    [
      {
        Component: Input,
        label: "Video Link",
        type: "text",
        name: "video",
        registerFields: {
          // required: true,
        },
      },
      {
        Component: Input,
        label: "Thumbnail",
        name: "image",
        type: "file",
        registerFields: {
          required: false,
        },
        inputData: {
          accept: "image/*",
        },
        children: oldImage && (
          <div style={{ position: "relative", width: "fit-content" }}>
            <span onClick={handleImageRemove} className="remove-icon">
              <i class="fa fa-times"></i>
            </span>
            <img
              src={`${API.PORT}/${oldImage}`}
              width={100}
              height={100}
              alt=""
              data-fancybox
            />
          </div>
        ),
      },
    ],
    [
      // {
      //   Component: Textarea,
      //   label: "Description",
      //   name: "description",
      //   colClass: "col-lg-12",
      //   registerFields: {
      //     //   required: true,
      //   },
      // },
      {
        Component: CKEditorInput,
        colClass: "col-xl-12",
        label: "Description",
        name: "description",
        registerFields: {
          required: true,
        },
        otherRegisterFields: {
          manual: true,
          feedback: "description is required",
        },

        inputData: {
          onInstanceReady: (editor) => {
            ckEditorRef.current = editor;
          },
        },
        getValues,
        setValue,
        trigger,
        clearErrors,
        isEdit: true,
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Tutorial"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/tutorial" /*backPageNum: page */ },
            name: "Back To Tutorials",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Edit Tutorial
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    {/* {oldImage && (
                      <img
                        src={`${API.PORT}/${oldImage}`}
                        alt=""
                        width={100}
                        height={100}
                      />
                    )} */}

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
