import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { Input, RenderInputFields, SubmitButton } from "../Form/Form";
import { API } from "../../constant/api";

const Edit = (props) => {
  const { id: categoryId } = props.match.params;
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [oldImage, setOldImage] = useState("");

  const { response: responseFetchCategory, request: requestFetchCategory } =
    useRequest();

  const { response, request } = useRequest();

  useEffect(() => {
    if (categoryId) {
      requestFetchCategory("GET", `profile-category/${categoryId}`);
      document.title = "Edit Category - YogaMitra";
    }
  }, [categoryId]);

  useEffect(() => {
    if (responseFetchCategory) {
      const { name, image } = responseFetchCategory.category;
      setValue("name", name);
      setOldImage(image);
    }
  }, [responseFetchCategory]);

  useEffect(() => {
    if (response) {
      toast.success("Category has been updated successfully.");
      history.push("/profile-categories");
    }
  }, [response]);

  const onSubmit = (data) => {
    const { name, image } = data;

    const formData = new FormData();

    if (image.length !== 0) {
      formData.append("file", image[0]);
    }

    formData.append("name", name);
    if (oldImage) {
      formData.append("oldImage", oldImage);
    }
    formData.append("id", categoryId);

    request("PUT", "profile-category", formData);
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "Name",
        type: "text",
        name: "name",
        registerFields: {
          required: true,
        },
        colClass: "col-lg-6",
      },
      {
        Component: Input,
        label: "Image",
        name: "image",
        type: "file",
        registerFields: {
          required: false,
        },
        inputData: {
          accept: "image/*",
        },
        tooltip: {
          show: true,
          title: `Required resolution is 72*72`,
        },
        children: oldImage && (
          <img
            src={`${API.PORT}/${oldImage}`}
            width={100}
            height={100}
            alt=""
            style={{ cursor: "pointer" }}
            data-fancybox
          />
        ),
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Category"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/profile-categories" },
            name: "Back To Categories",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Edit Category
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    {/* <button
                      onClick={handleSubmit(onSubmit)}
                      style={{ display: "none" }}
                    ></button> */}

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
