import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Moment from "moment";
import Modal from "react-modal";

import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { SearchInput, SearchSubmitButton } from "../Form/Form";
import { addOneToDate } from "../../util/fn";

const OBJ_TABLE = {
  sender: "sender",
  "item Name": "itemName",
  "item Type":"itemType",
  receiver: "receiver",
  review: "review",
  rating: "rating",
  isReply: "isReply",
  "reviewed on": "createdAt",
  action: "status",
};

const searchQueryHandler = (
  page,
  per_page,
  sortBy,
  order,
  name = "",
  itemName = "",
  dateFrom = "1970-01-01",
  dateTo
) => {
  sortBy = OBJ_TABLE[sortBy];

  order = order.length > 0 ? order : "desc";

  dateFrom = dateFrom.length > 0 ? dateFrom : "1970-01-01";

  dateTo = dateTo ? addOneToDate(new Date(dateTo)) : addOneToDate(new Date());

  return `review/all?page=${page}&per_page=${per_page}&sortBy=${sortBy}&order=${order}&name=${name}&itemName=${itemName}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
};

const ViewAll = () => {
  const [reviews, setReviews] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "reviewed on",
    order: "desc",
  });

  const [showModal, setShowModal] = useState(false);
  const [rejectedReason, setRejectedReason] = useState("");
  const [modalId, setModalId] = useState("");

  const MySwal = withReactContent(Swal);

  const { records_per_page } = useSelector((state) => state.setting);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    setError,
    clearErrors,
  } = useForm();

  const { request, response } = useRequest();
  const { request: requestChangeStatus, response: responseChangeStatus } =
    useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }
    document.title = "Reviews - YogaMitra";
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      setReviews(response.reviews);
      setTotalDocuments((prev) =>
        response.totalDocuments ? response.totalDocuments : prev
      );
    }
  }, [response]);

  useEffect(() => {
    if (responseChangeStatus) {
      setShowModal(false);
      setRejectedReason("");

      const { id, newStatus } = responseChangeStatus;

      const oldReviews = [...reviews];

      const indexToChange = oldReviews.findIndex(
        (reviewsDetail) => reviewsDetail._id == id
      );
      oldReviews[indexToChange].status = newStatus;

      setReviews(oldReviews);

      toast.success("Review's status changed successfully.");
    }
  }, [responseChangeStatus]);

  useEffect(() => {
    if (responseDelete) {
      const { id } = responseDelete;
      let newReviews = [...reviews];
      newReviews = newReviews.filter(
        (reviewsDetail) => reviewsDetail._id != id
      );
      setReviews(newReviews);
      toast.success("Reviews has been deleted successfully.");
    }
  }, [responseDelete]);

  const fetchMoreData = ({ selected }) => {
    setReviews([]);
    const { name, itemName, method, dateFrom, dateTo } = getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        name,
        itemName,
        method,
        dateFrom,
        dateTo
      )
    );
  };

  const deleteHandler = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You want to delete this Plan?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestDelete("DELETE", "order", { id });
      } else if (result.isDismissed) {
      }
    });
  };

  const onSearchHandler = (data) => {
    const { name, itemName, method, dateFrom, dateTo } = getValues();

    if (dateFrom && dateTo) {
      if (Moment(dateFrom).isAfter(dateTo)) {
        setError("dateTo", {
          type: "manual",
        });
        return;
      }
    }

    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        name,
        itemName,
        method,
        dateFrom,
        dateTo
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("name");
    resetField("itemName");
    resetField("method");
    resetField("dateFrom");
    resetField("dateTo");
    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const { name, itemName, method, dateFrom, dateTo } = getValues();

    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        name,
        itemName,
        method,
        dateFrom,
        dateTo
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  const sortingHandler = (sortBy) => {
    const { name, itemName, method, dateFrom, dateTo } = getValues();

    if (currentSort.sortBy === sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          newOrder,
          name,
          itemName,
          dateTo
        )
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          "desc",
          name,
          itemName,
          method,
          dateFrom,
          dateTo
        )
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  };

  const changeStatusHandler = (id, s) => {
    // const reviewsDetail = reviews.find(
    //   (reviewsDetails) => reviewsDetails._id === id
    // );
    // const status = reviewsDetail.isActive === "true" ? "false" : "true";

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (s == "rejected") {
          setModalId(id);
          setShowModal(true);
        } else {
          requestChangeStatus("PUT", "review/status", {
            id,
            status: s,
          });
        }
      } else if (result.isDismissed) {
      }
    });
  };

  const InputFields = [
    {
      label: "Sender Name",
      name: "name",
      required: false,
    },
    {
      label: "Item Name",
      name: "itemName",
      required: false,
    },
    {
      label: "Date From",
      name: "dateFrom",
      isDate: true,
      clearErrors,
    },
    {
      label: "Date To",
      name: "dateTo",
      isDate: true,
      clearErrors,
      otherRegisterFields: {
        manual: true,
        feedback: "'To Date' cannot be smaller than 'From Date'",
      },
    },
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb title="Reviews" links={[{ to: "/", name: "Dashboard" }]} />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6">
                      Search
                    </a>

                    {/* <Link to="/order/add" className="btn btn-primary mr-2">
                      Add New Oder
                    </Link> */}
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6">
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6">
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0">
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={sortingHandler}
                      mainData={reviews}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      links={
                        [
                          // {
                          //   isLink: false,
                          //   name: "Deactivate",
                          //   click: changeStatusHandler,
                          //   title: "Click To Approve",
                          // },
                          // {
                          //   isLink: false,
                          //   name: "Activate",
                          //   click: changeStatusHandler,
                          //   title: "Click To Reject",
                          // },
                        ]
                      }
                      onlyDate={{
                        createdAt: "date",
                        startDate: "dateTime",
                        endDate: "dateTime",
                      }}
                      renderAs={{
                        status: (val, id) =>
                          val == "pending" ? (
                            <>
                              <button
                                onClick={() =>
                                  changeStatusHandler(id, "approved")
                                }
                                className="btn btn-success mr-2"
                              >
                                Approve
                              </button>
                              <button
                                onClick={() =>
                                  changeStatusHandler(id, "rejected")
                                }
                                className="btn btn-danger"
                              >
                                Reject
                              </button>
                            </>
                          ) : (
                            val.charAt(0).toUpperCase() + val.slice(1)
                          ),
                        isReply: (val) =>
                          val.charAt(0).toUpperCase() + val.slice(1),
                      }}
                      dontShowSort={["action"]}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={reviews.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={showModal}
        ariaHideApp={false}
        className="model_block"
        onRequestClose={() => setShowModal(false)}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Reason</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowModal(false)}
              >
                <i aria-hidden="true" className="ki ki-close"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group row my-2">
                    <textarea
                      className={`form-control form-control-solid form-control-lg`}
                      cols="30"
                      rows="5"
                      placeholder="Enter reason"
                      value={rejectedReason}
                      onChange={(e) => setRejectedReason(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
              <hr />

              <div
                className={`d-flex justify-content-between border-top mt-5 pt-10`}
              >
                {/* px-10 */}
                <div className="mr-2">
                  <button
                    onClick={() =>
                      rejectedReason.trim().length == 0
                        ? toast.error("Enter reason for rejection")
                        : requestChangeStatus("PUT", "review/status", {
                            id: modalId,
                            status: "rejected",
                            reason: rejectedReason,
                          })
                    }
                    type="button"
                    className="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="clearfix">&nbsp;</div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ViewAll;
