import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Input,
  RenderInputFields,
  SubmitButton,
  SelectInput,
} from "../Form/Form";

const Edit = (props) => {
  const { id: creatorId } = props.match.params;
  const history = useHistory();

  const [notToEdit, setNotToEdit] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
  } = useForm();

  const { response: responseFetchUser, request: requestFetchCreator } =
    useRequest();

  const { response, request } = useRequest();

  const { response: responsePlan, request: requestPlan } = useRequest();

  const [plans, setPlans] = useState([]);

  useEffect(() => {
    document.title = "Edit Creator - YogaMitra";
    // requestPlan("GET", "plan/all?page=1&per_page=100");
  }, []);

  useEffect(() => {
    if (creatorId) {
      requestFetchCreator("GET", `user/${creatorId}`);
    }
  }, [creatorId]);

  useEffect(() => {
    if (responsePlan) {
      setPlans(responsePlan.plans);
    }
  }, [responsePlan]);

  useEffect(() => {
    if (responseFetchUser) {
      const {
        name,
        email,
        contact,
        username,
        subscriptionId: {
          plan: { duration },
          planId,
        },
      } = responseFetchUser.user;
      setValue("name", name);
      setValue("email", email);
      setValue("contact", contact);
      setValue("username", username);
      // setValue("planId", planId);
      // setValue("duration", duration ?? "yearly");

      setNotToEdit(responseFetchUser.user.providers.length > 0);
    }
  }, [responseFetchUser]);

  useEffect(() => {
    if (response) {
      toast.success("Creator has been updated successfully.");
      history.push("/creators");
    }
  }, [response]);

  const onSubmit = (data) => {
    const {
      name,
      email,
      contact,
      username,
      // duration, planId
    } = data;

    let {
      email: oldEmail,
      contact: oldContact,
      username: oldUsername,
      // subscriptionId: {
      //   plan: { duration: oldDuration },
      //   planId: oldPlanId,
      // },
    } = responseFetchUser.user;

    // oldDuration = oldDuration ?? "yearly";

    let updates = {};

    if (email.trim() != oldEmail) {
      updates.email = email;
    }

    if (contact) {
      const str = contact.toString();
      if (str.length > 0) {
        updates.contact = contact;
      }
    }

    if (username && oldUsername && username.trim() != oldUsername) {
      if (!/^[a-zA-Z0-9\\_.]+$/.test(username.trim())) {
        setError("username", {
          type: "manual",
        });
        return;
      }
    }

    if (username) {
      updates.username = username.trim();
    }

    // if (oldDuration !== duration) {
    //   updates.duration = duration;
    //   updates.planId = planId;
    // }

    // if (oldPlanId !== planId) {
    //   updates.planId = planId;
    //   updates.duration = duration;
    // }

    request("PUT", "user", {
      name,
      ...updates,
      userId: creatorId,
    });
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "Name",
        type: "text",
        name: "name",
        registerFields: {
          required: true,
          pattern: /^[A-Za-z ]+$/,
        },
        registerFieldsFeedback: {
          pattern: "Name can only contain letters.",
        },
      },
      {
        Component: Input,
        label: "Email",
        type: "email",
        name: "email",
        registerFields: {
          required: true,
          pattern:
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        },
        otherRegisterFields: {
          manual: true,
          feedback: "The email field must be a valid email address.",
        },
        inputData: {
          disabled: notToEdit,
        },
      },
    ],
    [
      {
        Component: Input,
        label: "Username",
        type: "text",
        name: "username",
        registerFields: {
          required: true,
          // pattern: /^[a-zA-Z0-9\\_.]+$/,
        },
        otherRegisterFields: {
          manual: true,
          feedback:
            "Username can contain only letters, numbers and symbols . or _",
        },
      },
      {
        Component: Input,
        label: "Phone Number",
        type: "number",
        name: "contact",
        registerFields: {
          // required: true,
          // minLength: 10,
          // maxLength: 10,
        },
      },
    ],
    // [
    //   {
    //     Component: SelectInput,
    //     label: "Select Plan",
    //     name: "planId",
    //     registerFields: {
    //       required: true,
    //       minLength: 1,
    //     },
    //     children: (
    //       <>
    //         <option value="">{"Select Plan"}</option>
    //         {plans.length > 0 &&
    //           plans.map((p, index) => (
    //             <option key={index} value={p._id}>
    //               {p.name}
    //             </option>
    //           ))}
    //       </>
    //     ),
    //   },
    //   {
    //     Component: SelectInput,
    //     label: "Duration",
    //     name: "duration",
    //     registerFields: {
    //       required: true,
    //       minLength: 1,
    //     },

    //     children: (
    //       <>
    //         <option value="">Select Duration</option>
    //         <option value="monthly">Monthly</option>
    //         <option value="yearly">Yearly</option>
    //       </>
    //     ),
    //   },
    // ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Creator"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/creators" /*backPageNum: page */ },
            name: "Back To Creators",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Edit Creator
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
