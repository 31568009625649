import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Input,
  RenderInputFields,
  SubmitButton,
  SelectInput,
} from "../Form/Form";

const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    // resetField,
    control,
    setError,
    clearErrors,
  } = useForm();

  const [users, setUsers] = useState([]);
  const [idProof, setIdProof] = useState("");
  const [isBankAccExist, setIsBankAccountExist] = useState(false);

  const { response, request } = useRequest();

  const { response: responseUser, request: requestUser } = useRequest();

  const history = useHistory();

  useEffect(() => {
    document.title = "Add Bank Account - YogaMitra";
    requestUser("GET", "admin/creators");
  }, []);

  useEffect(() => {
    if (responseUser) {
      setUsers(responseUser.user);
    }
  }, [responseUser]);

  useEffect(() => {
    if (response) {
      toast.success("Bank Account has been added successfully.");
      history.push("/bank-accounts-listing");
    }
  }, [response]);

  const onSubmit = (data) => {
    const { creator, accountName, accountNumber, accountIFSC, idProof } = data;

    const formData = new FormData();

    formData.append("file", idProof[0]);
    formData.append("ifsc", accountIFSC);
    formData.append("name", accountName);
    formData.append("accountNumber", accountNumber);
    formData.append("userId", creator.value);

    request("POST", "admin/bank-account-create", formData);
  };

  const isBankAccountExistHandler = (val) => {
    const user = users.find((u) => u._id == val.value);
    if (user.bankAccId) {
      // setError("creator", {
      //   type: "manual",
      // });
      setIsBankAccountExist(true);
    } else {
      // clearErrors("creator");
      setIsBankAccountExist(false);
    }
  };

  const InputFields = [
    [
      // {
      //   Component: SelectInput,
      //   label: "Select User",
      //   name: "userId",
      //   registerFields: {
      //     required: true,
      //     minLength: 1,
      //   },
      //   children: (
      //     <>
      //       <option value="">{"Select User"}</option>
      //       {user.length > 0 &&
      //         user.map((p, index) => (
      //           <option key={index} value={p._id}>
      //             {p.name}
      //           </option>
      //         ))}
      //     </>
      //   ),
      // },
      {
        Component: Input,
        label: "Account Name",
        type: "text",
        name: "accountName",
        registerFields: {
          required: true,
          pattern: /^[A-Za-z ]+$/,
        },
        registerFieldsFeedback: {
          pattern: "The Name field is invalid.",
        },
      },
      {
        Component: Input,
        label: "Account Number",
        type: "number",
        name: "accountNumber",
        registerFields: {
          required: true,
          pattern: /^\d{9,18}$/,
        },
        registerFieldsFeedback: {
          pattern: "The Account number field is invalid.",
        },
      },
    ],
    [
      {
        Component: Input,
        label: "Account IFSC",
        type: "text",
        name: "accountIFSC",
        registerFields: {
          required: true,
          pattern: /^[A-Z]{4}0[A-Z0-9]{6}$/,
        },
        registerFieldsFeedback: {
          pattern: "The IFSC code field is invalid.",
        },
      },
      {
        Component: Input,
        label: "Account Proof",
        type: "file",
        name: "idProof",
        registerFields: {
          required: true,
        },
        inputData: {
          accept: "image/*",
          onChange: (e) => {
            setIdProof(URL.createObjectURL(e.target.files[0]));
          },
        },
        children: idProof && (
          <img
            src={idProof}
            width={100}
            height={100}
            alt=""
            style={{ cursor: "pointer" }}
            data-fancybox
          />
        ),
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add Bank Account"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: "/bank-accounts-listing",
            name: "Back To Bank Accounts Listing",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Add New Bank Account
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group">
                          <label>
                            Creator
                            <span className="text-danger">*</span>
                          </label>
                          <Controller
                            control={control}
                            name="creator"
                            {...register("creator", {
                              required: true,
                            })}
                            render={({ field: { onChange, value, ref } }) => (
                              <Select
                                onChange={(val) => {
                                  onChange(val);
                                  isBankAccountExistHandler(val);
                                }}
                                defaultValue={[]}
                                value={value}
                                options={[
                                  ...users.map((val) => {
                                    return {
                                      value: val._id,
                                      label: `${val.name} ${
                                        val.email ? `(${val.email})` : ""
                                      } ${
                                        val.contact && val.countryCode
                                          ? `(${val.countryCode}${val.contact})`
                                          : ""
                                      }`,
                                    };
                                  }),
                                ]}
                                className={`basic-multi-select ${
                                  errors.creator || isBankAccExist
                                    ? "is-invalid"
                                    : ""
                                }`}
                                classNamePrefix="select"
                                menuPosition="fixed"
                              />
                            )}
                          />
                          {errors.creator?.type === "required" && (
                            <div className="invalid-feedback">
                              This field is required.
                            </div>
                          )}
                          {/* {errors.creator?.type === "manual" && (
                            <div className="invalid-feedback">
                              Bank account already added for this creator.
                            </div>
                          )} */}
                          {isBankAccExist && (
                            <div className="invalid-feedback">
                              Bank account already added for this creator.
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
