import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Input,
  RenderInputFields,
  SubmitButton,
  Textarea,
  SelectInput,
} from "../Form/Form";

const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    unregister,
  } = useForm();

  const { response, request } = useRequest();

  const history = useHistory();

  const [features, setFeatures] = useState([{ id: 0 }]);
  const [nextId, setNextId] = useState(1);

  useEffect(() => {
    document.title = "Add Plan - YogaMitra";
  }, []);

  useEffect(() => {
    if (response) {
      toast.success("Plan has been added successfully.");
      history.push("/plan");
    }
  }, [response]);

  const addFeature = () => {
    setFeatures((prev) => [...prev, { id: nextId }]);
    setNextId((prev) => prev + 1);
  };

  const deleteFeature = (id) => {
    const newFeatures = [...features].filter((f) => f.id !== id);
    setFeatures(newFeatures);

    unregister(`featureName${id}`);
    // unregister(`featureAvailable${id}`);
  };

  const onSubmit = (data) => {
    const {
      name,
      description,
      classCount,
      eventCount,
      passCount,
      price,
      // duration,
      yearlyPrice,
    } = data;

    const featuresArr = [];

    for (let i = 0; i < features.length; i++) {
      const id = features[i].id;
      if (data[`featureName${id}`]) {
        // featuresArr.push({
        //   isAvailable: data[`featureAvailable${id}`],
        //   feature: data[`featureName${id}`],
        // });
        featuresArr.push(data[`featureName${id}`]);
      }
    }

    request("POST", "plan", {
      name,
      description,
      classCount,
      eventCount,
      passCount,
      price,
      duration: 1,
      yearlyPrice,
      features: featuresArr,
    });
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "Name",
        type: "text",
        name: "name",
        registerFields: {
          required: true,
        },
      },
      // {
      //   Component: SelectInput,
      //   label: "Duration",
      //   name: "duration",
      //   registerFields: {
      //     required: true,
      //     minLength: 1,
      //   },
      //   children: (
      //     <>
      //       <option value="">{"Select Plan Duration"}</option>
      //       <option value={1}>{"1 month"}</option>
      //       <option value={3}>{"3 months"}</option>
      //       <option value={6}>{"6 months"}</option>
      //       <option value={12}>{"1 year"}</option>
      //     </>
      //   ),
      // },

      {
        Component: Input,
        label: "Monthly Price",
        type: "number",
        name: "price",
        min: 0,
        registerFields: {
          required: true,
          min: 0,
        },
      },
      {
        Component: Input,
        label: "Yearly Price",
        type: "number",
        name: "yearlyPrice",
        min: 0,
        registerFields: {
          required: true,
          min: 0,
        },
      },
      {
        Component: Input,
        label: "Class Count (per day)",
        type: "number",
        name: "classCount",
        min: 0,
        registerFields: {
          required: true,
          min: 0,
        },
        registerFieldsFeedback: {
          min: "The class field must be positive.",
        },
      },
      {
        Component: Input,
        label: "Workshop Count (per day)",
        type: "number",
        name: "eventCount",
        min: 0,
        registerFields: {
          required: true,
          min: 0,
        },
      },
      {
        Component: Input,
        label: "Pass Count (per month)",
        type: "number",
        name: "passCount",
        min: 0,
        registerFields: {
          required: true,
          min: 0,
        },
      },
    ],
    [
      {
        Component: Textarea,
        label: "Description",
        type: "text",
        name: "description",
        registerFields: {
          required: false,
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add Plan"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/plan", name: "Back To Plan" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Add New Plan
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="col-xl-12">
                      <div className="form-group">
                        <label className="mr-5">Features</label>
                        <button
                          onClick={addFeature}
                          className="btn btn-primary mr-2"
                          type="button"
                        >
                          Add
                        </button>
                        {features.map((feature) => (
                          <div key={feature.id} className="d-flex mt-5">
                            {/* <input
                              {...register(`featureAvailable${feature.id}`)}
                              className="mr-10"
                              type="checkbox"
                            /> */}
                            <div className="d-flex flex-column col">
                              <input
                                type="text"
                                className={`form-control form-control-solid form-control-lg ${
                                  errors[`featureName${feature.id}`] &&
                                  "is-invalid"
                                }`}
                                placeholder="Enter Feature"
                                required
                                {...register(`featureName${feature.id}`, {
                                  required: true,
                                })}
                              />
                              {errors[`featureName${feature.id}`]?.type ===
                                "required" && (
                                <div className="invalid-feedback">
                                  Either fill this field or delete.
                                </div>
                              )}
                            </div>
                            <button
                              onClick={() => deleteFeature(feature.id)}
                              className="btn btn-bg-danger ml-2"
                              type="button"
                            >
                              Delete
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
