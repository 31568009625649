import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { Textarea, RenderInputFields, SubmitButton, Input } from "../Form/Form";

const Edit = (props) => {
  const { id: reasonId } = props.match.params;
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const { response: responseFetchReason, request: requestFetchReaon } =
    useRequest();

  const { response, request } = useRequest();

  useEffect(() => {
    if (reasonId) {
      requestFetchReaon("GET", `report-reason/${reasonId}`);
      document.title = "Edit Reason - YogaMitra";
    }
  }, [reasonId]);

  useEffect(() => {
    if (responseFetchReason) {
      const { reason } = responseFetchReason.reportReason;
      setValue("reason", reason);
    }
  }, [responseFetchReason]);

  useEffect(() => {
    if (response) {
      toast.success("Reason has been updated successfully.");
      history.push("/report-reasons");
    }
  }, [response]);

  const onSubmit = (data) => {
    const { reason } = data;

    request("PUT", "report-reason", {
      reason,
      id: reasonId,
    });
  };

  const InputFields = [
    [
      {
        Component: Input,
        type: "text",
        label: "Reason",
        name: "reason",
        colClass: "col-lg-12",
        registerFields: {
          required: true,
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Reason"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/report-reasons" },
            name: "Back To Report reasons",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Edit Reason
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
