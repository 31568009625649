import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { Input, RenderInputFields, SubmitButton } from "../Form/Form";

const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    // resetField,
    setError,
  } = useForm();

  const { response, request } = useRequest();

  const history = useHistory();

  useEffect(() => {
    document.title = "Add Seeker - YogaMitra";
  }, []);

  useEffect(() => {
    if (response) {
      toast.success("Seeker has been added successfully.");
      history.push("/seekers");
    }
  }, [response]);

  const onSubmit = (data) => {
    const { name, email, username, contact, password, confirmpassword } = data;

    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      setError("email", {
        type: "manual",
      });
      return;
    }

    if (password !== confirmpassword) {
      setError("confirmpassword", {
        type: "manual",
      });
      return;
    }

    request("POST", "user/create", {
      email,
      password,
      name,
      username,
      contact,
    });
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "Name",
        type: "text",
        name: "name",
        registerFields: {
          required: true,
          pattern: /^[A-Za-z ]+$/,
        },
        registerFieldsFeedback: {
          pattern: "Name can only contain letters.",
        },
      },
      {
        Component: Input,
        label: "Email",
        type: "email",
        name: "email",
        registerFields: {
          required: true,
          pattern:
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        },
        registerFieldsFeedback: {
          pattern: "The email field must be a valid email address.",
        },
      },
    ],
    [
      {
        Component: Input,
        label: "Username",
        type: "text",
        name: "username",
        registerFields: {
          required: true,
          pattern: /^[a-zA-Z0-9\\_.]+$/,
        },
        registerFieldsFeedback: {
          pattern:
            "Username can contain only letters, numbers and symbols . or _",
        },
      },
      {
        Component: Input,
        label: "Phone Number",
        type: "number",
        name: "contact",
        registerFields: {
          // required: true,
          // minLength: 10,
          // maxLength: 10,
        },
      },
    ],
    [
      {
        Component: Input,
        label: "Password",
        type: "password",
        name: "password",
        registerFields: {
          required: true,
          pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
        },
        registerFieldsFeedback: {
          pattern:
            "Password must be of 8 characters long with atleast one Uppercase, one Lowercase and one Number",
        },
      },
      {
        Component: Input,
        label: "Confirm Password",
        type: "password",
        name: "confirmpassword",
        registerFields: {
          required: true,
          pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
        },
        registerFieldsFeedback: {
          pattern:
            "Confirm password must be of 8 characters long with atleast one Uppercase, one Lowercase and one Number",
        },
        otherRegisterFields: {
          manual: true,
          feedback: "Both Passwords do not match.",
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add Seeker"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/seekers", name: "Back To Seeker" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Add New Seeker
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
