import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { Wallet as WalletSvg } from "../../util/Svg";

const ViewWallet = (props) => {
  const { id: userId } = props.match.params;

  const [walletHistory, setWalletHistory] = useState([]);
  const [walletBalance, setWalletBalance] = useState(0);
  const [currency, setCurrency] = useState("INR");

  const { response, request } = useRequest();

  const history = useHistory();

  useEffect(() => {
    document.title = "Wallet History - YogaMitra";
    request("GET", `user/wallet-history?id=${userId}`);
  }, []);

  useEffect(() => {
    if (response) {
      setWalletHistory(response.walletHistory);
      setWalletBalance(response.walletBalance);
      setCurrency(response.currency);
    }
  }, [response]);

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="View Wallet"
        links={[{ to: "/", name: "Dashboard" }]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <Link
                      to={`/wallet/add/${userId}`}
                      className="btn btn-primary"
                    >
                      Update Wallet
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="walletTransaction">
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="walletBlock">
                          <div className="walletBlock_head">
                            <WalletSvg />
                            Wallet Balance
                          </div>
                          <div className="walletBalBlock">
                            {currency == "USD" ? "$" : "₹"}{" "}
                            <span>{walletBalance}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div className="Theme_table">
                          <div className="table-responsive scrollbar">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Description</th>
                                  <th>Price</th>
                                  {/* <th>Type</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {walletHistory.length > 0 &&
                                  walletHistory.map((history) => (
                                    <tr key={history._id}>
                                      <td>
                                        {moment(history.createdAt).format(
                                          "DD.MM.YY"
                                        )}
                                      </td>
                                      <td>
                                        {/* {history.type == "credit"
                                ? history.itemName //Received -
                                : "Payout"} */}
                                        {history.itemName}{" "}
                                        {history.isAdmin && (
                                          <span className='font-weight-bolder'>(By Admin)</span>
                                        )}
                                      </td>
                                      <td>
                                        <span
                                          className={`${
                                            history.type == "credit"
                                              ? "completeTxt"
                                              : "dueTxt"
                                          }`}
                                        >
                                          {history.type == "credit" ? "+" : "-"}{" "}
                                          {history.currency == "USD"
                                            ? "$"
                                            : "₹"}
                                          {history.amount}
                                        </span>
                                      </td>
                                      {/* <td>
                              <span className="typeTxt">Paid</span>
                            </td> */}
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewWallet;
