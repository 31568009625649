import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Textarea,
  RenderInputFields,
  SubmitButton,
  Input,
  CKEditorInput,
} from "../Form/Form";

const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    clearErrors,
  } = useForm();

  const ckEditorRef = useRef();

  const { response, request } = useRequest();

  const history = useHistory();

  useEffect(() => {
    document.title = "Add Faq - YogaMitra";
  }, []);

  useEffect(() => {
    if (response) {
      toast.success("Faq has been added successfully.");
      history.push("/faq");
    }
  }, [response]);

  const onSubmit = (data) => {
    const { question, answer } = data;

    request("POST", "faq", {
      question,
      answer,
    });
  };

  const InputFields = [
    [
      {
        Component: Input,
        type: "text",
        label: "Question",
        name: "question",
        colClass: "col-lg-12",
        registerFields: {
          required: true,
        },
      },
    ],
    [
      // {
      //   Component: Textarea,
      //   label: "Answer",
      //   name: "answer",
      //   colClass: "col-lg-12",
      //   registerFields: {
      //     required: true,
      //   },
      // },
      {
        Component: CKEditorInput,
        colClass: "col-xl-12",
        label: "Answer",
        name: "answer",
        // registerFields: {
        //   required: true,
        // },
        getValues,
        setValue,
        trigger,
        inputData: {
          onInstanceReady: (editor) => {
            ckEditorRef.current = editor;
          },
        },
        clearErrors,
        isEdit: false,
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add FAQ"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/faq", name: "Back To FAQs" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Add New FAQ
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
