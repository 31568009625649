import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Input,
  CKEditorInput,
  SelectInput,
  RenderInputFields,
  SubmitButton,
  Textarea,
} from "../Form/Form";

const Edit = (props) => {
  const { id: smsTemplateId } = props.match.params;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    reset,
    setError,
    clearErrors,
  } = useForm();

  const ckEditorRef = useRef();
  const textAreaRef = useRef();

  const [emailConstants, setEmailConstants] = useState([]);
  // const [isDataSet, setIsDataSet] = useState(false);

  const { response, request } = useRequest();

  const { response: responseFetchTemplate, request: requestFetchTemplate } =
    useRequest();

  const history = useHistory();

  useEffect(() => {
    // register("body", { required: true });
    requestFetchTemplate("GET", `sms-template/${smsTemplateId}`);
    document.title = "Edit Sms Template - YogaMitra";
  }, []);

  useEffect(() => {
    if (responseFetchTemplate) {
      const { body, constants, name } = responseFetchTemplate.smsTemplate[0];
      reset({ body, name });
      setEmailConstants(constants);
      // setIsDataSet(true);
    }
  }, [responseFetchTemplate]);

  useEffect(() => {
    if (response) {
      toast.success("Sms Template has been updated successfully.");
      history.push("/sms-template");
    }
  }, [response]);

  const insertConstantHandler = () => {
    const constant = getValues("constant");
    if (constant.length == 0) {
      return;
    }

    // ckEditorRef.current.model.change((writer) => {
    //   writer.insertText(
    //     `{${constant}}`,
    //     ckEditorRef.current.model.document.selection.getFirstPosition()
    //   );
    // });
    // ckEditorRef.current.editor.insertText(`{${constant}}`);

    const selectionStart = textAreaRef.current.selectionStart;
    const selectionEnd = textAreaRef.current.selectionEnd;

    const textAreaValueCV = getValues("body");

    let newValue =
      textAreaValueCV.substring(0, selectionStart) +
      `{${constant}}` +
      textAreaValueCV.substring(selectionEnd, textAreaValueCV.length);

    setValue("body", newValue);
  };

  const onSubmit = (data) => {
    const { body, name } = data;
    if (body.length === 0) {
      setError("body", {
        type: "manual",
      });
      return;
    }
    request("PUT", "sms-template", {
      body,
      name,
      id: smsTemplateId,
    });
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "Name",
        type: "text",
        name: "name",
        registerFields: {
          required: true,
          disabled: true,
        },
      },
    ],
    [
      {
        Component: SelectInput,
        label: "Constants",
        name: "constant",
        registerFields: {},
        children: (
          <>
            <option value="">{"Select Constant"}</option>
            {emailConstants.length > 0 &&
              emailConstants.map((constant, index) => (
                <option key={index} value={constant}>
                  {constant}
                </option>
              ))}
          </>
        ),
        moreData: (
          <button
            onClick={insertConstantHandler}
            type="button"
            className="btn btn-success font-weight-bold text-uppercase px-9 py-4 mb-6"
          >
            Insert
          </button>
        ),
      },
    ],
    [
      // {
      //   Component: CKEditorInput,
      //   colClass: "col-xl-12",
      //   label: "Sms Body",
      //   name: "body",
      //   registerFields: {
      //     required: true,
      //   },
      //   otherRegisterFields: {
      //     manual: true,
      //     feedback: "Sms Body is required",
      //   },

      //   inputData: {
      //     onInstanceReady: (editor) => {
      //       ckEditorRef.current = editor;
      //     },
      //   },
      //   getValues,
      //   setValue,
      //   trigger,
      //   clearErrors,
      //   isEdit: true,
      // },
      {
        Component: Textarea,
        colClass: "col-xl-12",
        label: "Sms Body",
        name: "body",
        registerFields: {
          required: true,
        },
        inputData: {
          ref: textAreaRef,
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Sms Template"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/sms-template", name: "Back To Sms Template" },
        ]}
      />
      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Update Sms Template
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    {/* {isDataSet && ( */}
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />
                    {/* )} */}

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
